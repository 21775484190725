@import "src/styles/varibals";

.MemberItemWrap {
  display: flex;
  margin-bottom: 20px;
  cursor: pointer;
  // color: $colorPrimary;
  position: relative;
  align-items: center;

  svg {
    flex-basis: 20%;
  }
  .fa-balance-scale {
    flex-basis: 5%;
  }
}

.MemberEmailLabel {
  margin-right: 10px;
  display: inline-block;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 150px;
  margin-left: -2px;
  // font-family: "Open Sans", sans-serif !important;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 14px !important;
  color: rgba(0, 0, 0, 0.95) !important;
}

.MemberItemWrapPopup {
  padding: 0 !important;
  width: 0;
  height: 0;

  &:before {
    display: none;
  }
}

.MemberPendingLabel {
  position: absolute;
  color: yellow !important;
  background-color: rgba(0, 0, 0, 0.9) !important;
  width: 20px;
  height: 20px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  left: -6;
}

.MemberItemIcon {
  margin-right: 0px;
}

.MembersCountOfTask {
  margin-right: 5px;
  font-weight: normal;
  position: absolute;
  left: 20px;
  top: -5px;
  background-color: #daedff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #555;
}

.GradeGroup {
  display: flex;
  align-items: center;
  padding: 10px 25px;
  span {
    font-weight: 900;
    font-family: "Roboto", sans-serif;
    color: rgba(0, 0, 0, 0.87);
  }
  svg {
    color: rgba(0, 0, 0, 0.95) !important;
    font-size: 18px;
    overflow: visible;
    box-sizing: content-box;
    flex-basis: 17%;
    flex-grow: 0;
  }
}