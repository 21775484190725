@import "src/styles/varibals";

.Container {
  display: flex;
  align-items: stretch;
  position: relative;

  .Taskforce,
  .Taskforcemessage {
    position: absolute;
    top: 50%;
    left: 65px;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    font-size: 16px;
  }

  .Taskforcemessage {
    color: $predStatusBlue !important;
  }

  .Taskmessage {
    position: absolute;
    top: 25%;
    left: 65px;
    transform: translateY(-50%);
  }

  .Taskmessage {
    position: absolute;
    top: 25%;
    left: 65px;
    transform: translateY(-50%);
  }

  padding: 10px 5px;
  cursor: pointer;

  &.Active {
    background-color: $colorHover;
    .GoToRelationBtn {
      opacity: 0.4;
    }

    .IconWrap {
      .ProgressBar {
        background: $backgroundMain;
      }
    }
  }

  &.MorePadding {
    padding-left: 80px;
    padding-right: 80px;
  }

  &:hover {
    .GoToRelationBtn {
      opacity: 0.4;
    }
  }

  &:not(.Active):hover {
    background-color: $colorLightHover;
  }
}

.GoToRelationBtn {
  opacity: 0;
  transition: 0.1s;
  align-items: center;
  display: flex;
  padding: 0 10px 0 5px;
  font-size: 40px;

  &:hover {
    opacity: 1 !important;
  }
  &.GoToPred {
    margin-right: 45px;
  }

  &.GoToSuc {
    margin-left: 10px;
  }
}

.index {
  font-size: 12px;
  font-weight: 600;
  // font-family: "Open Sans", sans-serif;
  font-family: "Roboto", sans-serif;
  color: $colorDark;
  position: absolute;
  top: 50%;
  left: 30px;
  transform: translateY(-50%);
  text-align: right;
  width: 25px;
}

.rightFilters {
  display: flex;
  align-items: baseline;
  margin-top: 10px;

  .Blinking {
    animation: blinking-anim 1.2s infinite;
  }
  @keyframes blinking-anim {
    45% {
      opacity: 0.2;
    }
    50% {
      opacity: 0.2;
    }
  }

  .criticalIn {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    min-width: 140px;

    p {
      margin-bottom: 0;
    }
    .critical {
      background-color: #ec808d;
      padding: 0px 5px;
      text-align: center;
      font-weight: 400;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .criticalPath {
        padding: 0px 5px;
        text-align: center;
        font-weight: 400;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $predStatusRed;
        border: 1px solid $predStatusRed;
    }

    .nearCritical {
      background-color: #facd91;
      padding: 0px 5px;
      text-align: center;
      font-weight: 400;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.Description {
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;

  h4,
  h5 {
    margin: 0;
    color: $colorMidDark;
  }

  h4 {
    color: $colorMidDark;
    font-size: 14px;
    font-weight: 600;
    // font-family: "Open Sans", sans-serif;
    font-family: "Roboto", sans-serif;
  }

  h5 {
    font-size: 14px;
    font-weight: 300;
    // font-family: "Open Sans", sans-serif;
    font-family: "Roboto", sans-serif;
  }
}

.Reason {
  background: $backgroundPrimary;
  color: #fff;
  font-size: 12px;
  padding: 1px 5px;
  margin-left: 10px;
  max-height: 17px;
}

.IconWrap {
  margin-right: 15px;
  position: relative;
  display: flex;
  align-items: center;
  width: 50px;
  height: 50px;
  position: relative;
  justify-content: center;
  border: 1px solid transparent;

  &:hover {
    background-color: $backgroundActive;
    // background-color: $backgroundPrimary;
  }
}

.SnoozeIcon {
  position: absolute;
  top: -5px;
  right: 5px;
  font-size: 16px;
  color: #333;
}

.PredTypeLabel {
  color: $colorDark;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  font-size: 16px;
}

.LoaderWrap {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

// .ProgressBarWrap {
//   position: absolute;
//   margin-right: 10px;
// }

.ProgressBar {
  position: absolute;
  top: -1px;
  right: -1px;
  width: 3px;
  height: 50px;
  border-radius: 5px;
  background: $colorLight;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.LineBlue {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 3px;
  height: 25px;
  background: $predStatusBlue;
}

.LineGreen {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 3px;
  height: 25px;
  background: $predStatusGreen;
}

.LineYellow {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 3px;
  height: 25px;
  background: $predStatusYellow;
}

.LineRed {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 3px;
  height: 25px;
  background: $predStatusRed;
}

.TaskInfoDates {
  font-size: 14px;
  color: $colorDark;
// font-family: "Open Sans", sans-serif;
  font-family: "Roboto", sans-serif;
  min-width: 100px;
}

.TaskInfoDatesReforecast {
  font-size: 14px;
  color: darkcyan;
  // font-family: "Open Sans", sans-serif;
  font-family: "Roboto", sans-serif;
  min-width: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: -20px;
  margin-left: 20px;
}

.ReforecastIcon {
  background-color: darkcyan;
  font-size: 9px;
  color: white;
  border-radius: 50%;
  min-width: 14px;
  min-height: 14px;
  max-width: 14px;
  max-height: 14px;
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.TaskInfo {
  font-size: 14px;
  font-weight: 400;
  color: $colorDarker;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  // font-family: "Open Sans", sans-serif;
  font-family: "Roboto", sans-serif;
  min-width: 140px;

  .Taskforce {
    color: $colorDark;
    font-size: 16px;
  }

  .Negative {
    color: $predStatusRed !important;
  }

  p {
    color: $colorDark;
    font-weight: 300;
    font-size: 12px;
  }

  .overdue {
    background-color: $predStatusRed !important;
    color: $backgroundMain !important;
  }
}

.OptionWrap {
  top: 10px !important;
  padding: 0 !important;
  width: 0;
  height: 0;

  &:before {
    display: none;
  }
}

.TaskCode {
  font-size: 12px;
  color: $colorMidDark;
}

.TaskName {
  display: flex;
  flex-direction: column;
}

.TaskNarrative {
  font-size: 12px;
  color: $colorDark;
  font-weight: 300;
  font-family: "Roboto", sans-serif;
  height: 100%;
  padding-left: 10px;
}

.SprintIcon {
  margin-left: 5px;
  margin-top: 2px;
  font-size: 16px;
  font-weight: bold;
}

.FastForwardIcon {
  border-style: solid;
  border-radius: 50%;
  font-size: 9px;
  padding: 2px;
  border-width: 1px;
}

.FastForwardIconSolid {
  border-style: solid;
  border-radius: 50%;
  font-size: 9px;
  padding: 2.5px;
  border-width: 1px;
  background-color: black;
  color: white;
}

.PlannedStartIcon {
    color: $predStatusGreen;
}

.PlannedFinishIcon {
    color: $predStatusBlue;
}

.UnplannedStartIcon {
    color: rgb(159, 206, 182);
}

.UnplannedFinishIcon {
    color: rgb(145, 182, 243);
}

.EarlyIcon {
  color: $colorLight;
}


.SprintIcons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3px;
  margin-left: 4px;
  font-size: 15px;
}

.SprintAction {
  margin-left: 8px;
  font-size: 18px;
  font-weight: bold;
}

.TaskDetails {
  display: flex;
  flex-direction: row;
}

.MilestoneBlue {
  color: $predStatusBlue !important;
}

.MilestoneGreen {
  color: $predStatusGreen !important;
}

.MilestoneYellow {
  color: $predStatusYellow !important;
}

.MilestoneRed {
  color: $predStatusRed !important;
}

.TaskProgress {
  display: grid;
  grid-template-columns: 95% 5%;
  margin-top: 8px;
  align-items: center;
  justify-content: center;
}

.SliderContainer {
  min-height: 8px;
  max-height: 8px;
  width: 100%;
  display: flex;
  flex-direction: row;
  //background-image: linear-gradient($colorLight, #efeeee, $colorLight);
  background-color: $colorLight;
  border-radius: 3px;
  cursor: auto;
}

.SliderValue {
  margin-left: 10px;
}

.SliderProgressBlue {
  min-height: 8px;
  max-height: 8px;
  height: 100%;
  background-image: linear-gradient(to right, $predStatusBlue, #91B6F3FF);
  border-radius: 3px;
  cursor: auto;
}

.SliderProgressGreen {
  min-height: 8px;
  max-height: 8px;
  height: 100%;
  background-image: linear-gradient(to right, $predStatusGreen, #9FCEB6FF);
  border-radius: 3px;
  cursor: auto;
}

.SliderProgressYellow {
  min-height: 8px;
  max-height: 8px;
  height: 100%;
  background-image: linear-gradient(to right, $predStatusYellow, #F3E1ADCC);
  border-radius: 3px;
  cursor: auto;
}

.SliderProgressRed {
  min-height: 8px;
  max-height: 8px;
  height: 100%;
  background-image: linear-gradient(to right, $predStatusRed, #d6979f);
  border-radius: 3px;
  cursor: auto;
}


.SliderButtonBlue {
  max-height: $sliderButtonHeight;
  min-height: $sliderButtonHeight;
  max-width: $sliderButtonHeight;
  min-width: $sliderButtonHeight;
  background-color: $predStatusBlue;
  font-size: 11px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid $predStatusBlue;
  color: $predStatusBlue;
  margin-left: -8px;
  margin-top: -5px;

  &:hover {
    background-color: $predStatusBlue;
    color: white;
  }
}

.SliderButtonGreen {
  max-height: $sliderButtonHeight;
  min-height: $sliderButtonHeight;
  max-width: $sliderButtonHeight;
  min-width: $sliderButtonHeight;
  background-color: $predStatusGreen;
  font-size: 11px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid $predStatusGreen;
  color: $predStatusGreen;
  margin-left: -8px;
  margin-top: -5px;

  &:hover {
    background-color: $predStatusGreen;
    color: white;
  }
}

.SliderButtonRed {
  max-height: $sliderButtonHeight;
  min-height: $sliderButtonHeight;
  max-width: $sliderButtonHeight;
  min-width: $sliderButtonHeight;
  background-color: $predStatusRed;
  font-size: 11px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid $predStatusRed;
  color: $predStatusRed;
  margin-left: -8px;
  margin-top: -5px;

  &:hover {
    background-color: $predStatusRed;
    color: white;
  }
}

.SliderButtonYellow {
  max-height: $sliderButtonHeight;
  min-height: $sliderButtonHeight;
  max-width: $sliderButtonHeight;
  min-width: $sliderButtonHeight;
  background-color: $predStatusYellow;
  font-size: 11px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid $predStatusYellow;
  color: $predStatusYellow;
  margin-left: -8px;
  margin-top: -5px;

  &:hover {
    background-color: $predStatusYellow;
    color: white;
  }
}