@import "./varibals";

$openWidth: 300px;
$minOpenWidth: 300px;
$maxOpenWidth: 600px;
$closeWidth: 0px;

$navigationItemHeight: 45px;

.navigation-bar-overlay {
  position: absolute;
  z-index: 301;
  width: 100%;
  height: 100%;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.4);
}

.navigation-bar-Logo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 20px;
  border-bottom: 1px solid #f2f2f2;
  position: relative;
}

.new-project-tag {
    position: absolute;
    top: 0;
    right: 0;
    background-color: $predStatusGreen;
    color: white;
    padding: 3px 5px;
    border-radius: 5px;
    font-size: 12px;
}

.update-project-tag {
  position: absolute;
  top: 0;
  right: 0;
  background-color: $predStatusBlue;
  color: white;
  padding: 3px 5px;
  border-radius: 5px;
  font-size: 12px;
}

.upload-loader {
  width: 100%;
  height: 14px;
  opacity: 0.5;
  background: repeating-linear-gradient(45deg,$predStatusBlue 0 15px,#0000 0 20px) right/200% 100%;
  animation: l3 8s infinite linear;
  border-radius: 3px;
  margin-top: 10px;
}
@keyframes l3 {
  100% {background-position:left}
}


.navigation-bar-Logo .header-menu-content {
  position: absolute;
  left: 0;
  .header-menu-button-wrap {
    margin: 0;
    position: relative;
    width: 100%;

    .header-menu-button {
      border-radius: 0.28571rem;
      background-color: transparent;
      color: #fff;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 19px;
      transition: opacity 0.05s;
      text-align: left;
      position: relative;
      padding: 0 10px 0 20px;
      height: 100%;
    }
  }
}

.navigation-bar-Logo .menu-burger {
  width: 25px;
  position: relative;

  span {
    transition: 0.2s;
    display: block;
    width: 100%;
    height: 2px;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }

    background: black;
  }

  &.times-mode {
    span {
      &:nth-child(2) {
        opacity: 0;
      }

      &:first-child {
        transform: rotate(45deg) translate(5px, 5px);
      }

      &:last-child {
        transform: rotate(-45deg) translate(5px, -5px);
      }
    }
  }
}

.navigation-bar-Logo img {
  width: 70px;
}

.progress-bar-container {
  height: 25px;
  width: 25px;
  margin-right: 15px;
  cursor: pointer;
}

.error-icon {
  color: $backgroundError;
  font-size: 20px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  border: 1px solid $backgroundError;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: rgba(204, 73, 74, 0.21);
  &:hover {
    background-color: $backgroundError;
    color: white;
  }
}

.cancelled {
  font-size: 12px;
}

.upload-modal-progress-bar {
  min-height: 12px;
  max-height: 12px;
  position: relative;
  min-width: 100%;
  max-width: 100%;
  overflow: hidden;
  border: 1px solid #f2f2f2;
  border-radius: 2px;
  background-color: $colorLight
}

.upload-modal-status {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: $predStatusBlue;
  background-color: #ecf1f8;
  padding: 5px;
  margin-top: 10px;
  border-radius: 3px;

  &.error {
    color: $backgroundError;
    background-color: rgba(204, 73, 74, 0.21);
  }
}

.upload-modal-progress-bar-fill {
  min-height: 12px;
  max-height: 12px;
  background-image: linear-gradient(to bottom, $predStatusBlue, #79a6f1, $predStatusBlue);
}

.navigation-bar-container {
  display: flex;
  flex-direction: column;
  //width: $openWidth;
  // min-width: $minOpenWidth;
  // max-width: $maxOpenWidth;
  transform: translateX(-$minOpenWidth);
  background-color: $backgroundMain;
  overflow: hidden;
  border-right: 1px solid $backgroundActive;
  position: fixed;
  height: 100vh;
  z-index: 1000;
  top: 0px;
  transition: 0.3s;
  -webkit-transform: translateX(-$minOpenWidth);
  -moz-transform: translateX(-$minOpenWidth);
  -ms-transform: translateX(-$minOpenWidth);
  -o-transform: translateX(-$minOpenWidth);
  .inner-list {
    .title {
      display: flex;
      align-items: center;
      color: rgba(0, 0, 0, 0.87) !important;

      &.active {
        color: $predStatusBlue !important;
      }

      i {
        position: relative;
        font-size: 20px;

        &::before {
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      &.disabled {
        color: #cccccc !important;
        cursor: not-allowed;

        &:hover {
          color: #cccccc !important;
        }
      }

      &:hover {
        color: #4285f4 !important;
      }
    }
  }

  &.open {
    transform: translateX(0px);
  }

  &.close {
    transform: translateX(-300px);
  }
}

.navigation-bar-content {
  width: $openWidth;
  // min-width: $minOpenWidth;
  // max-width: $maxOpenWidth;

  .item {
    font-weight: bold;
  }

  & > .list {
    overflow: auto;
    height: calc(100vh - 180px);
    padding: 10px 0;
    margin: 0;
  }
}

.navigation-accordion {
  padding: 10px !important;

  &.ui.accordion {
    .accordion {
      margin: 0;
    }
  }
}

.navigation-accordion-project {
  box-shadow: none !important;

  .chevron-icon {
    font-size: 30px;
  }

  & > .content.active {
    padding: 0 !important;
  }
}

.navigation-accordion-container {
  box-shadow: none !important;
}

.navigation-accordion-item {
  .list-item {
    position: relative;
    display: flex;
    align-items: center;
    flex-grow: 1;

    svg {
      font-size: 20px;
      margin-right: 1rem;
      height: 20px;
    }

    svg.fa-thumbs-down {
      width: 25px;
      height: 25px;
      padding: 5px;
      background-color: #d9001b;
      border-radius: 15px;
      margin-left: -5px;
      color: white !important;
    }

    .notify-label {
      position: absolute;
      width: 20px;
      height: 20px;
      display: flex;
      left: 10px;
      z-index: 10;
      top: -10px;
      background: $predStatusRed;
      border-radius: 100px;
      font-size: 11px;
      color: #fff;
      justify-content: center;
      transition: 0.1s;
      font-family: "Roboto", sans-serif;
      text-align: center;
      line-height: normal;
      align-items: center;
    }
  }
  svg {
    height: 25px;
  }
}

.navigation-accordion-item-active-project {
  font-weight: bold !important;
  color: $predStatusBlue !important;
  display: flex;

  i {
    &::before {
      font-size: inherit;
    }
  }

  &:hover {
    color: $predStatusBlue !important;
  }
}

.navigation-option {
  & > .title {
    border-radius: 0.28571rem;
    background-color: transparent;
    color: rgba(0, 0, 0, 0.95);
    border: none;
    cursor: pointer;
    font-size: 19px;
    transition: opacity 0.2s;
    text-align: left;
    display: flex;
    align-items: center;
    position: relative;
    padding: 10px 12px !important;
    opacity: 1 !important;
    font-weight: 600;

    &:hover,
    &:active,
    &:focus,
    &.active {
      color: $predStatusBlue;
    }

    .icon-projects,
    .icon-archive {
      font-size: 20px;
    }

    p {
      font-size: 16px;
      margin-left: 10px;
      font-weight: 700;
      font-family: "Roboto", sans-serif;
      text-transform: uppercase;
    }
  }

  .inner-list {
    padding: 0 25px !important;
  }
}

.navigation-bar-button-wrap {
  position: relative;
  width: 100%;

  &:hover .navigation-title,
  &:focus .navigation-title,
  &:active .navigation-title {
    color: $predStatusBlue;
  }

  svg {
    font-size: 20px !important;
  }

  &:hover svg,
  &:focus svg,
  &:active svg {
    color: $predStatusBlue;
  }
}

.navigation-bar-button {
  border-radius: $borderRadiusButtonDefault;
  background-color: transparent;
  color: rgba(0, 0, 0, 0.95);
  border: none;
  cursor: pointer;
  font-size: 19px;
  transition: opacity 0.2s;
  text-align: left;
  display: flex;
  align-items: center;
  position: relative;
  padding: 10px 20px;
  opacity: 1 !important;

  .navigation-title {
    font-size: 14px;
    margin-left: 10px;
    transition: opacity 0.2s;
    white-space: nowrap;
  }
}

.navigation-bar-button-content {
  display: flex;
  overflow: hidden;
  align-items: center;

  svg {
    flex-basis: 15%;
  }
}

.navigation-bar-dropdown-wrap {
  position: absolute;
  left: calc(100%);
  top: 0;
  min-height: 100%;
  display: flex;
}

.navigation-bar-dropdown-content {
  //position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;

  .navigation-bar-dropdown-item {
    margin-bottom: 10px;
    background-color: #fff;
    padding-left: 34px !important;
    padding-right: 14px !important;

    &:hover,
    &:focus {
      background-color: rgba(203, 230, 255, 0.7);
    }

    &.btn-active {
      border: 1px solid $predStatusBlue;
      color: $predStatusBlue;
    }
  }
}

.navigation-bar-dropdown-item {
  width: 100%;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  background-color: $backgroundLight;

  &:hover {
    background-color: $backgroundActive;
  }

  // .navigation-bar-dropdown-item {

  .navigation-bar-label-wrap {
    display: flex;
    align-items: center;
    height: 100%;
    flex: 1;
    color: rgba(0, 0, 0, 0.95);

    svg {
      margin-right: 10px;
      font-size: 20px;
      flex-basis: 15%;
      display: none;
    }

    i {
      transition: 0.05s;
      opacity: 0;
    }
  }

  .navigation-bar-label {
    display: inline-block;
    padding-right: 10px;
    white-space: nowrap;
  }
}

.create-new-task-btn-container {
  display: flex;
  justify-content: space-between;
}

.modal-leave-project,
.modal-close-project {
  .modal-leave-project-header,
  .modal-close-project-header {
    text-align: center !important;
  }

  .actions {
    text-align: center !important;
    margin: 0 auto !important;
  }

  .close {
    top: -2.5rem !important;
    right: -2.5rem !important;
  }
}

.modal-nomination {
  overflow: hidden;

  .modal-nomination-header {
    text-align: center !important;
  }

  .actions {
    text-align: center !important;
    margin: 0 auto !important;
  }

  .nomination-members {
    max-height: 200px;
    overflow: auto;

    .nomination-member {
      margin-top: 5px;
    }
  }
}

.navigation-bar-notifications-item {
  display: flex;
  align-items: center;
  padding: 16px 25px;
  border-bottom: 1px solid $backgroundActive;
}

.navigation-bar-notifications-item-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 30px;
  width: 100%;

  &:hover svg,
  &:hover h4 {
    color: $predStatusBlue;
    cursor: pointer;
  }

  h4 {
    margin-top: 0;
    font-size: 16px;
    margin-left: -3px;
    font-weight: 700;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
  }

  .notification-header {
    display: flex;
    svg {
      font-size: 20px;
    }
  }

  svg {
    height: 25px;
  }
}

.navigation-bar-notifications-item-title {
  h4,
  p {
    margin: 0;
  }

  h4 {
    font-weight: 600;
    font-size: 16px;
  }

  p {
    font-weight: 300;
    font-size: 12px;
  }
}
