@import '../../styles/varibals.scss';

.LandingPage {
    width: 100%;
    overflow: auto;
    font-family: 'Source Sans Pro Light', 'Source Sans Pro', sans-serif;
    scroll-behavior: smooth;
}

.Header {
    width: 100%;
    padding: 20px;
    background-color: $backgroundPrimary;
    color: #fff;
    display: flex;
    align-items: center;
    font-size: 16px;
    position: fixed;
    z-index: 1000;
    opacity: 0.9;
}

.Logo {
    flex-basis: 15%;
    text-decoration: none;
    color: #fff;
    font-size: 20px;
    font-weight: bold;

    &:hover {
        color: rgba($color: $colorHover, $alpha: 1); 
    }
}

.Navigation {
    flex-grow: 1;
    display: flex;
    justify-content: space-around;
}

.NavLink {
    color: #fff;

    &:hover {
        color: rgba($color: $colorHover, $alpha: 1.0);
    }
}

.Login {
    flex-basis: 10%;
    display: flex;
    justify-content: flex-end;
    text-decoration: none;
    color: #fff;

    &:hover {
        color: rgba($color: $colorHover, $alpha: 1.0);    
    }
}

.Hero {
    padding-top: 70px;
    height: 100vh;
    font-size: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.Footer {
    padding: 10px 20px;
    background-color: $backgroundPrimary;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    & > {
        flex-basis: 50%;
    }
}

.Section {
    padding: 30px 20px;
    color: $backgroundPrimary;
    background: linear-gradient(#fff, $colorHover, #fff);

    &:nth-child(2n) {
        background: none;
        background-color: #fff;

        .SectionHeader {
            color: $predStatusBlue;
        }
    }
}

.SectionHeader {
    text-align: center;
    font-size: 30px;
    font-family: 'Roboto Bold', 'Roboto Regular', 'Roboto', sans-serif;
    margin-bottom: 10px;
}

.SectionSubTitle {
    text-align: center;
    font-size: 18px;
    color: $predStatusBlue;
}

.LightBlue {
    background-color: $colorHover;
}

.List {
    list-style: none;
}

.ListItem {
    padding: 10px 0;
    font-size: 16px;
}

.ListTitle {
    font-size: 20px;
    color: $predStatusBlue;
    padding: 5px 0;
}

.Form {
    display: flex;
    flex-wrap: wrap;
    padding: 30px 0;
}

.FormFlex {
    display: flex;
    flex-direction: column;
    & > *:first-child {
        width: 50% !important;
    }
    & > *:last-child {
        width: 100% !important;
    }
}

.Input {
    flex-basis: 50%;
    padding: 0 10px;
}

.Checkbox {
    flex-basis: 100%;
    padding: 0 10px;
}

.SubmitButton {
    margin: 0 10px !important;
    background-color: $predStatusBlue !important;
    border-radius: 0 !important;

    &:hover {
        background-color: rgba($color: $predStatusBlue, $alpha: 0.9) !important;
    }
}

.ImageFlow {
    margin: 30px 0;
    width: 100%;
    border-radius: 20px;
    border: 1px solid rgba($color: $predStatusBlue, $alpha: 0.5);
}

.Loader {
    left: 50% !important;
    transform: translateX(-50%) !important;
}

.LogoImage {
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    height: 100%;
    width: 100%;
}

.UploadingButton {
    width: 50%;

    & > button {
        color: #fff !important;
        background-color: $predStatusBlue !important;
        border-radius: 0 !important;
    }
}

.UploadingInputWrapper {
    height: 38px;
    position: relative;
    border: 1px solid rgba(34, 36, 38, 0.15);
    z-index: 1000;
    cursor: pointer;
    border-radius: .28571429rem;
    width: 100%;
}

.PlaceholderInput {
    position: absolute;
    opacity: 0.6;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.Point {
    width: 100%;
    margin: 10px !important;
}

.FormAddWrapper {
    display: flex;
    width: 100%;
    padding: 0 10px;
}

.FormAddWrapperFilled {
    display: flex;
    width: 100%;
    padding: 0 10px;
    flex-direction: column;
}