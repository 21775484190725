@import "varibals";

.header-additional-content-wrap {
  position: initial;
  width: 0;
  transition: width 0.3s;
  overflow: hidden;

  &.open {
    width: 360px;
    border-left: 1px solid $backgroundActive;

    h4 {
      font-size: 1rem;
      line-height: 20px;
    }
  }
}

.header-additional-content {
  width: 360px;
}

@keyframes openAdditional {
  from {
    width: 0;
  }
  to {
    width: 360px;
  }
}

.profile-wrapper {
  padding: 15px 10px;
}

.header-wrapper {
  padding: 0 !important;
  width: 100%;
  z-index: 275;

  button {
    padding: 0 20px;
    margin: 0;
  }

  .button-profile {
    background-color: transparent !important;
    white-space: nowrap;
    padding: 0 20px;
    margin: 0;
    display: flex;
    align-items: center;
    color: #fff;

    &:hover {
      cursor: pointer;
      opacity: 0.9;
    }
  }
}


//make image round
.user-image {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  //margin-top: 15px;
  //border: 1px solid #9b9a9a;

  &:hover {
    cursor: pointer;
    mask: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  }
}

.user-name-sidebar {
    font-weight: 700;
    font-family: "Roboto", sans-serif;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;

}

.user-sidebar {
  font-family: "Roboto", sans-serif;
}

.sidebar-menu-item {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
}

.user-image-div {
  border-radius: 50%;
  margin-right: 10px;
  //margin-top: 15px;
  //border: 1px solid #9b9a9a;
  //background-color: #0d71bb;
  color: white;
  align-items: center;
  text-align: center;
  font-size: 20px;
  vertical-align: center;
  display: flex;
  justify-content: center;

  &:hover {
    cursor: pointer;
    mask: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  }
}
.user-image-div-sidebar {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  //margin-top: 15px;
  //border: 1px solid #9b9a9a;
  //background-color: #0d71bb;
  color: white;
  align-items: center;
  text-align: center;
  font-size: 30px;
  vertical-align: center;
  display: flex;
  justify-content: center;
  font-family: "Roboto", sans-serif;

  &:hover {
    cursor: pointer;
    mask: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  }
}

.update-string {
  font-size: 12px;
  color: #797979;
  padding: 0;
  margin: 0;
  position: absolute;
  bottom: 3px;
  left: 86px
}

.ui.header.white-text.m-0.flowledger-header-title {
  padding-left: 30px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;

  .project-title {
    font-family: "Roboto Bold", "Roboto Regular", "Roboto", sans-serif;
    font-size: 27px;
    font-weight: 700;
    color: #fff;
    line-height: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-top: -10px;
    padding: 0;
  }

  .flowledger-header-subtitle {
    font-family: "Source Sans Pro", "Roboto Regular", "Roboto", sans-serif;
    font-family: "Roboto Bold", "Roboto Regular", "Roboto", sans-serif;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
    color: #fff;
    padding-left: 7px;
  }
}

.header-menu-container {
  display: flex;
  justify-content: center;
  padding: 10px 0;
  transition: width 0.3s;
  margin: 0;
  overflow: hidden;

  .header-menu-content {
    display: flex;

    .header-menu-button-wrap {
      margin: 0;
      position: relative;
      width: 100%;

      .header-menu-button {
        border-radius: 0.28571rem;
        background-color: transparent;
        color: #fff;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 19px;
        transition: opacity 0.05s;
        text-align: left;
        position: relative;
        padding: 0 10px 0 20px;
        height: 100%;
      }
    }
  }
}

.notifications-label {
  position: absolute;
  width: 20px;
  height: 20px;
  display: flex;
  left: 35px;
  z-index: 10;
  top: 5px;
  background: $predStatusRed;
  border-radius: 100px;
  font-size: 11px;
  color: #fff;
  justify-content: center;
  transition: 0.1s;
  font-family: "Roboto", sans-serif;
  text-align: center;
  line-height: normal;
  align-items: center;

  &.hide {
    opacity: 0;
  }
}

.menu-burger {
  width: 25px;
  position: relative;

  span {
    transition: 0.2s;
    display: block;
    width: 100%;
    height: 2px;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }

    background: white;
  }

  &.times-mode {
    span {
      &:nth-child(2) {
        opacity: 0;
      }

      &:first-child {
        transform: rotate(45deg) translate(5px, 5px);
      }

      &:last-child {
        transform: rotate(-45deg) translate(5px, -5px);
      }
    }
  }
}

.view-date-picker {
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    transition: 0.2s;
    max-width: 200px;
    min-width: 200px;
    width: 200px;

    .view-date-picker-icon {
        margin-right: 5px;
    }
}

.progress-bar {
  border-radius: 0 !important;
  font-size: 10px !important;
  margin: 0 !important;
  z-index: 240;
  width: 100%;

  .bar {
    transition: none !important;
    box-shadow: -2px 4px 12px -1px #000;
  }

  & div {
    border-radius: 0 !important;
    background-color: $predStatusBlue !important;
  }

  .rocket-button-wrap {
    position: absolute;
    top: -20px;
    background-color: transparent !important;
  }

  .rocket-icon-chart {
    color: white;
    font-size: 16px;
    background-color: $predStatusBlue;
  }

  .rocket-button-dropdown {
    position: absolute;
    padding: 0;
    margin: 0;
    max-height: 25px;
    min-height: 25px;
    width: 25px;
    max-width: 25px;
    min-width: 25px;
    background-color: transparent !important;
    .rocket-button-dropdown-item {
      background-color: white !important;
    }
    .rocket-button-dropdown-item:hover {
      background-color: whitesmoke !important;
    }
    .rocket-button-dropdown-menu {
      border-radius: 5px !important;
      background-color: white !important;
    }
  }

  .rocket-button-icon {
    color: white;
    font-size: 16px;
  }

  .rocket-button {
    background-color: $predStatusBlue;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -17px;
    padding: 0;

    .rocket-button-icon {
      font-size: 15px;
    }

    &:hover,
    &:active {
      opacity: 0.9;
      background-color: $predStatusBlue;
    }

    &:focus {
      background-color: $predStatusBlue;
    }
  }

  .react-datetime-picker__wrapper {
    border: none;
  }

  .modal-dateinput {
    text-align: center !important;
    display: block;
    margin: 0 auto;
    position: relative;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.4);
  }

  .green-button {
    background-color: #0F9D58;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -17px;
    padding: 0;
    position: absolute;

    .green-button-icon {
      color: #fff;
      font-size: 15px;
    }

    &:hover,
    &:active {
      opacity: 0.9;
      background-color: #0f9d58;
    }

    &:focus {
      background-color: #0f9d58;
    }
  }

  .red-button {
    background-color: #DB4437;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -17px;
    padding: 0;
    position: absolute;

    .red-button-icon {
      color: #fff;
      font-size: 15px;
    }

    &:hover,
    &:active {
      opacity: 0.9;
      background-color: #db4437;
    }

    &:focus {
      background-color: #db4437;
    }
  }

  .completed-tasks-percentage {
    display: none;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
  }

  &:hover .completed-tasks-percentage {
    display: block;
  }

  &.no-progress {
    & div.bar {
      background-color: transparent !important;
    }

    &:hover .completed-tasks-percentage {
      display: none;
    }
  }
}

.react-datepicker-wrapper input[type="text"]{
  border: 0.5px solid #d4d4d5;
  border-radius: 5px;
  padding: 5px;
  min-width: 100%;
}

.delete-icon {
  cursor: pointer;
  margin-left: 10px;
}

.delete-icon:hover {
  color: red;
}

.milestone-modal-table-wrapper {
  max-height: 400px;
  overflow: auto;
}

.milestone-modal-table-header {
  position: sticky;
  top: 0;
  z-index: 500;
}

.baseline-date-picker {
  text-align: center !important;
}

.portal.menu.transition {
  & > * {
    padding: 10px;

    &:hover {
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
}

.ui.page.modals.dimmer.transition.visible.active {
  overflow: initial;
}

.debug-modal-content {
  font-size: 16px !important;
  text-align: center;
  height: 500px;
  overflow: auto;
  word-break: break-all;
}

.debug-modal-header {
  text-align: center;
}

.Flowledger-Logo {
  display: flex;
  align-items: center;
  img {
    width: 90px;
    height: fit-content;
  }
}

.project-content {
  min-height: calc(100vh - 140px);
  overflow: hidden;
}

.open-chat {
  position: fixed;
  top: calc(100vh - 80px);
  right: 40px;
  z-index: 1000;
  background-color: $predStatusBlue;
  opacity: 0.8;
  color: white;
  font-size: 1.8rem;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  &:hover {
    opacity: 1;
  }
}

.chat-container {
    position: fixed;
    top: calc(100vh - 605px);
    right: 20px;
    z-index: 1000;
    width: 400px;
    height: 600px;
    max-height: 70vh;
    max-width: 50vw;
    background-color: white;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    border-radius: 10px;
}

.inner-chat-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.chat-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
      border-bottom: 1px solid #d4d4d5;
    background-color: $predStatusBlue;
    color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    > h4 {
      margin: 0;
    }
  .chat-minimise {
    cursor: pointer;
    font-size: 20px;
    margin-top: -12px;
    margin-right: 10px;
    &:hover {
      color: #d4d4d5;
    }
  }
}

.chat-footer {
  width: 100%;
  display: flex;
  position: absolute;
  bottom: 0;
  background-color: #e8eaec;
  justify-content: center;
    align-items: center;

  .chat-input {
    width: 98%;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.chat-body {
  max-height: calc(100% - 90px);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.user-message {
  float: right;
  background-color: #d1fae5;
  border-radius: 8px;
  padding: 5px;
  margin: 5px;
  max-width: 70%;
  box-shadow: -2px 2px 3px 0px rgba(0, 0, 0, 0.3);
}

.assistant-message {
  float: left;
  background-color: #e0f2fe;
  border-radius: 8px;
  padding: 8px;
  margin: 8px;
  max-width: 70%;
  box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.3);

  &.loading {
    width: 50px;
  }
}

.milestone {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  box-shadow: 0px 20px 10px -20px rgba(0, 0, 0, 0.45) inset;

  &.green {
    background-color: #CFE7D8;
  }

  &.red {
    background-color: #F6D1CE;
  }

  &-info {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #333;

    .name {
      font-weight: bold;
      margin: 0;
    }

    .code {
      margin: 0;
    }
  }

  &-actions {
    display: flex;
    align-items: center;
    gap: 10px;

    .deley {
      font-size: 14px;
      color: #333;
      font-weight: bold;
      margin: 0;
    }

    .driving-tasks {
      margin: 0;
      cursor: pointer;
      padding: 10px;
      font-size: 14px;
      border: 1px solid #333;
    }
  }
}

.btn {
  text-align: center;
  border: none;
  background: rgb(255, 255, 255, 0.4);

  color: black;
  padding: 0 4px;
  border-top: 0.5px solid #0a0a0a;
  cursor: pointer;
  margin: 2px 0 0;
}
.btn-lg {
  font-size: 12px;
  line-height: 1;
  padding: 4px;
}
.btn-zoom {
  width: 26px;
  font-size: 22px;
}
.btn-bottom {
  margin-bottom: 1rem;
}
.description {
  font-size: 12px;
  margin-right: 0.25rem;
}
.controls {
  position: absolute;
  top: 15px;
  right: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.mini-map {
  position: absolute;
  bottom: 25px;
  right: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.relative {
  position: relative;
}
