.delete-project-page {
    width: 100%;
    text-align: center;

    .header {
        text-align: center;
        padding: 20px 20px;
    }

    .list {
        padding: 20px 40px;
        max-width: 400px;
        margin: 0 auto;
        text-align: left;

        .list-item {
            padding: 10px 0;

            .checkbox {
                font-size: 16px;
            }
        }
    }

    .buttons {
        padding: 20px 0;
        & > * {
            margin: 0 10px;
        }
    }
}