@import "src/styles/varibals";

.RequestModalDiv {
  max-height: 70vh;
  min-height: 40vh;
  overflow-y: auto;
  flex: 1;
}

.AboutPreview {
  color: #959494;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Description {
  margin: 0;
  padding-bottom: 5px;

  > s {
    color: #7e7d7d;
  }
}

.TicketDiv {
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 8px;
  &:hover {
    background-color: $backgroundLight;
  }
}

.TicketPreview {
  min-width: 70vw;
  max-width: 70vw;
  border-left: 1px solid #e0e0e0;

  > h3 {
    padding-left: 10px;
    margin-bottom: 0;
  }

  > p {
    padding-left: 20px;
    font-size: 13px;
  }

  > span {
    display: flex;
    flex-direction: row;
    padding-left: 10px;
    padding-right: 10px;
    align-items: center;
    margin-bottom: 20px;
    > h3 {
      padding-left: 10px;
      margin-bottom: 0;
    }
    > p {
      padding-left: 10px;
      font-size: 13px;
      margin-bottom: 0;
    }
  }
}

.IssueTagDiv {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 0 8px;
  margin-left: 10px;
  font-weight: bold;
}

.RequestModalContainer {
  display: flex;
  flex-direction: row;
}

.SelectedTicket {
  background-color: $backgroundActive;
}

.OpenTicket {
  background-color: lightgrey;
}

.ClosedTicket {
  background-color: $predStatusGreen;
  color: white;
}

.InProgressTicket {
  background-color: $predStatusBlue;
  color: white;
}

.AttachmentContainer {
  border-top: 1px solid #e0e0e0;
  margin-left: 5px;

  > h5 {
    margin-top: 10px;
    margin-bottom: 0;
    padding-left: 15px;
  }
}

.AttachmentGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-gap: 10px;
  padding: 10px;
}

.AttachmentDiv {
  width: 100px;
  height: 100px;
  // hide the overflow
  overflow: hidden;
  border-radius: 10px;
  &:hover {
    cursor: pointer;
    // provide a mask
    filter: brightness(0.8);
  }
}

.ImageModal {
  max-width: 90%;
  max-height: 90%;
  margin: 0;
  padding: 0;
}

.TicketLoader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  >h3 {
    margin-bottom: 5px;
    margin-right: 5px;
  }
}

.LowPriority {
  background-color: lightgrey;
  color: black;
}

.MediumPriority {
  background-color: #ffcc00;
  color: black;
}

.HighPriority {
  background-color: #f6a1a1;
  color: #bd081c;
}

.HighestPriority {
  background-color: #ff0000;
  color: white;
}

.CommentContainer {
  border-top: 1px solid #e0e0e0;
  margin-left: 5px;

  > h5 {
    margin-top: 10px;
    margin-bottom: 0;
    padding-left: 15px;
  }
}

.CommentDiv {
  display: flex;
  flex-direction: column;
  padding: 15px;
}

.CommentText {
  margin-bottom: 2px;
  padding-left: 3px;
  //border-style: solid;
}

.AddACommentDiv {
    display: flex;
    flex-direction: column;
    padding: 15px;
}

.CommentInput {
    min-width: 100%;
    max-width: 100%;
    min-height: 70px;

}