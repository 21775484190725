@import "varibals";

@mixin activeTask {
  .task-item-content {
    background-color: #dfdede !important;
  }

  .task-item-button {
    opacity: 0.5;
  }

  .task-item-img-content {
    box-shadow: 0 0 5px 5px #ccc;
    border-radius: 10px 0px 10px 10px;

    &.active {
      padding: 0;
    }
  }
  .task-statuses-preview-wrap {
    border-radius: 10px 0 0 10px;
  }
}

.flow-page-container {
  overflow: auto;
  display: flex;
  min-height: fit-content;
}

.metrics-gauge-component-green {
    background-color: rgba(15, 157, 88, 0.42);
    min-height: 30px;
  border-right: solid 1px #c0c1c2;
}

.metrics-gauge-component-green-hover {
  background-color: $predStatusGreen;
  min-height: 30px;
  border-right: solid 1px #c0c1c2;
}

.metrics-gauge-component-red {
  background-color: #E47587;
  min-height: 30px;
  border-right: solid 1px #c0c1c2;
}

.metrics-gauge {
  display: flex;
  flex-direction: row-reverse;
  min-width: 98%;
  max-width: 98%;
  margin-left: 1%;
  min-height: 30px;
  border-radius: 10px;
  border-style: solid;
  overflow: hidden;
  border-width: 1px;
  color: #c0c1c2;
  margin-top: 10px;
  background-color: rgba(15, 157, 88, 0.42);
}

.metrics-gauge:hover {
  background-color: $predStatusGreen;
}


.metrics-box-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  border-width: 1px;
  border-radius: 10px ;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 3px;
  cursor: help;
  min-height: 100px;
  max-height: 100px;
}

.metrics-box-container > p {
  padding: 0;
  margin: 0;
}

.metrics-top-line {
    display: flex;
    align-items: center;
    width: 100%;
  margin-top: 5px;
}

.metrics-cluster {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-template-rows: repeat(auto-fill, minmax(100px, 1fr));
  flex-direction: row;
  flex: 1;
  overflow: hidden;
  margin-right: 15px;
}

.metrics-description {
  min-height: 50%;
  max-height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  align-items: center;
  text-align: center;
}

.metrics-overall-div {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-width: 1px;
  border-radius: 10px ;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 5px;
  cursor: help;
}

.metrics-score {
  border-radius: 10px;
  padding: 5px;
}

.task-loader-content-container {
  flex-wrap: wrap;
  flex: 1;
}

.task-predecessors-container {
  padding: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;

  .checklist-wrapper {
    background-color: $colorHover;
    word-break: break-word;
    padding: 0;
    padding-left: 114px;

    &.flex {
      display: flex;
    }

    .collapse-title {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-left: 20px;

      svg {
        margin-right: 8px;
        font-size: 22px;
      }

      p {
        font-size: 1rem;
        font-weight: 700;
        font-family: "Roboto", sans-serif;
        color: #333333;
      }
    }
  }

  .checklist {
    margin-left: 10px;
    margin-right: 48px;
    padding: 0 0 10px;

    .checklist-item-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      font-family: "Arial Regular", "Arial", sans-serif;
      color: $backgroundPrimary;
      line-height: normal;

      & > .close-icon {
        cursor: pointer;
        padding: 0 7px;
        color: $colorSecondary;
        font-size: 20px;
      }

      &:hover {
        background-color: $colorFourth;
      }

      .checklist-item-input-wrapper {
        display: flex;
        align-items: center;
      }

      .checklist-item {
        padding: 10px;
        flex-grow: 1;
        line-height: inherit;
        display: flex;
        align-items: center;

        & > .close-icon {
          cursor: pointer;
          padding: 0 7px;
        }

        label {
          padding-left: 30px;
          color: $backgroundPrimary;
          font-family: "Source Sans Pro Light", "Source Sans Pro", sans-serif;
          font-weight: 300;
          text-align: left;
          line-height: normal;

          &::after {
            color: #fff !important;
            background-color: $predStatusBlue;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -3px;
          }
          &::before {
            border-radius: 50%;
            width: 20px;
            height: 20px;
            top: -3px;
          }
        }
      }
      .checklist-email {
        color: $backgroundMain;
        background-color: $predStatusBlue;
        padding: 1px 3px;
        margin: 0;
        border-radius: 5px;
      }
    }
  }

  .checklist-option {
    padding: 0 5px 10px;
    display: flex;
    align-items: center;
    // margin-left: 114px;
    // margin-right: 45px;
    height: 40px;

    div.input {
      width: 50%;
      margin-left: 40px;

      input {
        border: 1px solid $predStatusBlue;
        border-radius: 0;
      }
    }

    .btn-add {
      background-color: $predStatusBlue;
      color: #fff;
      border-radius: 0;
      padding: 10px 15px;
    }

    .close-icon {
      cursor: pointer;
      margin: 0 15px;
      font-size: 30px;
      display: flex;
      align-items: center;

      .btn-add {
        background-color: $predStatusBlue;
        color: #fff;
      }

      .close-icon {
        cursor: pointer;
        margin: 0 10px;
        font-size: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #616161;

        &:hover {
          opacity: 0.9;
        }
      }
    }
  }

  .add-checklist {
    margin-left: 18px;
    border: 1px solid #d7d7d7;
    padding: 7px !important;
    background-color: transparent;
    border-radius: 0;
    font-family: "Arial Regular", "Arial", sans-serif;
    color: $backgroundPrimary;
    text-align: center;
    font-weight: initial;

    &:hover {
      background-color: $backgroundPrimary;
      color: #fff;
    }

    &.import {
      margin-right: -15px;
    }

    &.lock {
      background-color: $predStatusBlue;
      color: #fff;
      border: 1px solid $predStatusBlue;

      &-disabled {
        pointer-events: none;
        opacity: 0.8;
        border: 1px solid #999;
      }
    }
  }

  &.open {
    max-height: 400px;
  }
}

.task-predecessor-main {
  // background-color: $backgroundActive;
  padding: 10px 80px 10px 60px;
  display: flex;
  justify-content: space-between;
  color: $colorDark;
  font-weight: bold;
  font-size: 16px;
  border-top: 1px solid #f2f2f2;
  text-transform: uppercase;

  span:nth-child(2) {
    text-transform: capitalize;
  }
}

.task-predecessors-list-header {
  margin-top: 35px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #000;
  position: relative;
}

.task-successors-list {
  flex: 1;
  position: relative;
  overflow: auto;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 320px);
}

.task-predecessors-list {
  flex: 1;
  position: relative;
  overflow: auto;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 685px);
}

.task-predecessors-list-close-btn {
  cursor: pointer;
  //position: absolute;
  //right: 20px;
  //top: -30px;
  font-size: 27px;
}

.task-predecessors-item {
  width: 50%;
}

.task-item-container {
  cursor: pointer;
  display: flex;
  //width: 50% !important;
  //min-width: 400px;
  align-items: center;
  margin: 20px 0 !important;
  // width: 100% !important;

  //&.pred-status-green {
  //  border: 1px solid green;
  //  .task-item-dropdown-container {
  //      background-color: green($color: #000000);
  //  }
  //}
  &.item:first-child,
  &.item:last-child {
    margin: 20px 0 !important;
  }

  &:hover .task-item-dropdown-container {
    background-color: #dfdede;
  }

  .task-item-img-content {
    display: flex;
    flex: 1;
    transition: 0.2s;
    margin: 0 20px;
    position: relative;
    width: 100% !important;

    .task-item-content {
      transition: background-color 0.2s;
      padding: 15px 10px 10px 10px !important;
      position: relative;
      flex: 1;
      border-radius: 0 0 10px 0;
      // overflow: hidden;

      &.background-white {
        background-color: #fff !important;
      }

      .task-item-reason-label {
        position: absolute;
        background: #4f4f4f;
        color: #fff;
        right: 0;
        top: -27px;
        border-radius: initial;
        margin: 0;
        border: 1px solid black;
      }

      & > h4 {
        margin: 0 0 5px 0;
        // display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 150px;
      }

      .description {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 200px;
      }
    }

    .task-item-dropdown-container {
      border-radius: 10px;

      &:before {
        content: "";
        display: none;
        position: absolute;
        width: 15px;
        height: 15px;
        top: 85%;
        left: 50%;
        margin-left: -7.5px;
        background-color: inherit;
        transform: rotate(45deg);
      }

      &.upward {
        &:before {
          top: initial;
          bottom: 85%;
        }
      }
    }

    .task-item-dropdown-container.active.visible {
      &:before {
        display: block;
      }
    }
  }

  &:first-child {
    margin: 1em 0 !important;
  }

  &:last-child {
    margin: 1em 0 !important;
  }

  &.active {
    @include activeTask;
  }

  &:hover {
    @include activeTask;
  }

  .task-item-button {
    opacity: 0;

    &:hover {
      opacity: 1 !important;
    }
  }
}

.task-item-dropdown-container {
  //width: 50% !important;
  cursor: pointer;
}

.task-item-button {
  height: 100%;
  font-size: 34px;
  display: flex;
  align-items: center;
  opacity: 0;
  transition: opacity 0.2s;

  &.left-side {
    padding: 0 0 0 5px;
  }

  &.right-side {
    padding: 0 5px 0 0;
  }
}

.task-statuses-preview-container {
  min-width: 80px;
  display: flex;
  height: 100%;
  position: relative;
}

.task-statuses-preview-wrap {
  flex: 1;
  // background-color: #c7dbcb;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  position: relative;
  border-radius: 10px 0 0 10px;
  transition: 0.2s;

  &.background-white {
    background-color: #fff;
  }

  .task-statuses-taskforce {
    position: absolute;
    // border-radius: 10px;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(0, 0, 0, 0.255);
  }
}

.task-statuses-preview-loader {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #dadada;
}

.additional-task-dropdown-option-trigger {
  i {
    margin-right: 10px;
  }
}

.background-red {
  background-color: $predStatusRed;
}

.background-yellow {
  background-color: $predStatusYellow;
}

.background-green {
  background-color: $predStatusGreen;
}

.background-blue {
  background-color: $predStatusBlue;
}

.color-red {
  color: $predStatusRed;
}

.color-yellow {
  color: $predStatusYellow;
}

.color-green {
  color: $predStatusGreen;
}

.color-blue {
  color: $predStatusBlue;
}

.border-red {
  border: 1px solid $predStatusRed;
}

.border-yellow {
  border: 1px solid $predStatusYellow;
}

.border-green {
  border: 1px solid $predStatusGreen;
}

.border-blue {
  border: 1px solid $predStatusBlue;
}

.task-item-relations {
  position: absolute;
  left: 60px;
  background-color: #333333;
  color: #fff;
  width: 20px;
  text-align: center;
  border: 1px solid black;
}

.task-code {
  font-size: 10px;
  // width: 30%;
  text-align: right;
  display: inline-block;
  position: absolute;
  right: 5px;
  top: 5px;
}

.menu.transition.visible::after {
  display: none !important;
}

input[type="file"] {
  display: none;
  min-width: 100%;
}

.file-input-div {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border: 1px solid #dcdbdb;
  border-radius: 5px;
  min-width: 75%;
  max-width: 75%;
  cursor: pointer;
  margin-top: 10px;
  max-width: 100%;
  min-width: 100%;
}

.modal-event {
  width: 365px !important;
  text-align: center !important;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .deselect-checkboxes {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin: 10px 90px;
    overflow-y: scroll;
    max-height: 150px;

    & > * {
      padding: 5px 0;
    }

    label {
      max-width: 200px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .deselect-header {
    margin: 5px 0;
  }

  .ui.warning.message {
    width: initial;

    & > .icon:not(.close) {
      font-size: 1em !important;
      margin: 0 5px;
    }
  }

  & > .header {
    border-bottom: none !important;
    text-align: center !important;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif !important;
  }

  & > .actions {
    text-align: center !important;
    border-top: none !important;

    button {
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif !important;
    }
  }
}

.modal-event-div {
  width: 100% !important;
  display: grid;
  grid-template-columns: 50px 1fr;
  margin-top: 5px;

  > div {
    width: 100% !important;
    text-align: center;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    font-size: 12px;
  }
}

.modal-event-dateinput {
  text-align: center !important;
  display: block;
  margin: 0 auto;
  position: relative;
  padding: 10px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.4);

  input::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }

  input {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
  }
}

.paginationContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 20px 0 20px;
}

.task-list-accordion-wrap {
  border-bottom: 1px solid $colorLight;

  .task-list-accordion {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 50px 5px 15px;
    // background-color: $backgroundActive;

    .sprint-icon {
        font-size: 20px !important;
    }

    .chevron-icon {
      cursor: pointer;
      font-size: 30px;
      color: $backgroundLightGrey;

      &:hover,
      &:focus,
      &:active {
        color: $predStatusBlue;
      }
    }
  }

  .task-list-accordion-label {
    color: $backgroundPrimary;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    font-weight: 900;
    // font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
    font-family: "Roboto", sans-serif;

    .check-complete {
      cursor: pointer;
      color: $backgroundLightGrey;
      padding-left: 0;
      flex-basis: 1%;
      font-size: 30px !important;
      width: 25px;

      &:hover,
      &:focus,
      &:active {
        color: lime;
      }
    }

    .label-chevron-text {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      text-align: center;

      .label-text-only {
        margin-left: 15px;
      }
    }

    &:hover {
      svg {
        color: $predStatusBlue;
      }

      span {
        color: $predStatusBlue;

      }

      .custom-pagination {
        color: unset;
        // cursor: default;

        .number {
          cursor: default;
        }
        svg {
          color: unset;
          // cursor: default;
        }
      }
    }

    svg {
      flex-basis: 5%;
      font-size: 30px !important;
    }

    span {
      //flex-grow: 1;
      //border-style: solid;
        align-items: center;
      // text-transform: uppercase;
    }

    span.section-text {
      display: flex;
      justify-content: flex-end;
    }
  }

  .task-list-accordion-label-icon {
    color: $colorLight;
    margin-right: 10px;
  }

  .percentage {
    font-size: 16px;
    font-weight: 600;
    // font-family: "Open Sans", sans-serif;
    font-family: "Roboto", sans-serif;
  }

  .custom-pagination {
    display: flex;
    align-items: center;
    .number {
      color: #aaaaaa;
      font-size: 13px;
      font-weight: 500;
    }

    button {
      background: transparent;
      border: unset;
      color: #555;
      cursor: pointer;
      padding: unset;

      &:disabled {
        pointer-events: none;
        color: #aaaaaa;
      }

      svg {
        padding: 3px 9px;
        flex-basis: 5%;
        font-size: 16px !important;
        cursor: pointer;
      }
    }
  }

  .task-list-accordion-content {
    display: none;

    &.open {
      max-height: initial !important;
      display: initial;
    }

    transition: 0.4s;
    // max-height: 0;
    margin: 11px 11px 0 0;
    // overflow-y: scroll;
    // overflow: hidden;

    .task-container-wrapper {
      border-bottom: 1px solid #f2f2f2;
    }

    .checklist-wrapper {
      background-color: $colorHover;
      word-break: break-word;
      padding: 0;
      padding-left: 114px;

      &.flex {
        display: flex;
      }

      .collapse-title {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-left: 15px;

        svg {
          margin-right: 8px;
          font-size: 22px;

          &.lock-icon {
            margin-left: 8px;
            font-size: 15px;
            margin-top: -3px;
          }
        }

        p {
          font-size: 1rem;
          font-weight: 700;
          font-family: "Roboto", sans-serif;
          color: #333333;
        }
      }
    }

    .checklist {
      margin-left: 5px;
      margin-right: 48px;
      padding: 0 0 10px;

      .checklist-item-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        font-family: "Arial Regular", "Arial", sans-serif;
        color: $backgroundPrimary;
        line-height: normal;

        & > .close-icon {
          cursor: pointer;
          padding: 0 7px;
          color: $colorSecondary;
          font-size: 20px;
        }

        &:hover {
          background-color: $colorFourth;
        }

        .checklist-item-input-wrapper {
          display: flex;
          align-items: center;
        }

        .checklist-item {
          padding: 10px;
          flex-grow: 1;
          line-height: inherit;
          display: flex;
          align-items: center;

          & > .close-icon {
            cursor: pointer;
            padding: 0 7px;
          }

          label {
            padding-left: 30px;
            color: $backgroundPrimary;
            font-family: "Source Sans Pro Light", "Source Sans Pro", sans-serif;
            font-weight: 300;
            text-align: left;
            line-height: normal;

            &::after {
              color: #fff !important;
              background-color: $predStatusBlue;
              border-radius: 50%;
              width: 20px;
              height: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
              top: -3px;
            }
            &::before {
              border-radius: 50%;
              width: 20px;
              height: 20px;
              top: -3px;
            }
          }
        }
        .checklist-email {
          color: $backgroundMain;
          background-color: $predStatusBlue;
          padding: 1px 3px;
          margin: 0;
          border-radius: 5px;
        }
      }
    }

    .checklist-option {
      padding: 0 5px 10px;
      display: flex;
      align-items: center;
      // margin-left: 114px;
      // margin-right: 45px;
      height: 40px;

      div.input {
        width: 50%;
        margin-left: 40px;

        input {
          border: 1px solid $predStatusBlue;
          border-radius: 0;
        }
      }

      .btn-add {
        background-color: $predStatusBlue;
        color: #fff;
        border-radius: 0;
        padding: 10px 15px;
        margin-top: 5px;
        margin-left: 40px;
      }

      .close-icon {
        cursor: pointer;
        margin: 0 15px;
        margin-top: 5px;
        font-size: 30px;
        display: flex;
        align-items: center;

        .btn-add {
          background-color: $predStatusBlue;
          color: #fff;
        }

        .close-icon {
          cursor: pointer;
          margin: 0 10px;
          font-size: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #616161;

          &:hover {
            opacity: 0.9;
          }
        }
      }
    }

    .add-checklist {
      margin-left: 18px;
      border: 1px solid #d7d7d7;
      padding: 7px !important;
      background-color: transparent;
      border-radius: 0;
      font-family: "Arial Regular", "Arial", sans-serif;
      color: $backgroundPrimary;
      text-align: center;
      font-weight: initial;

      &:hover {
        background-color: $backgroundPrimary;
        color: #fff;
      }

      &.import {
        margin-right: -15px;
      }

      &.lock {
        background-color: $predStatusBlue;
        color: #fff;
        border: 1px solid $predStatusBlue;

        &-disabled {
          pointer-events: none;
          opacity: 0.8;
          border: 1px solid #999;
        }
      }
    }

    &.open {
      max-height: 400px;
    }
  }
}

.modal-event-message {
  width: 60% !important;
  margin: 10px auto !important;
}

@media (min-width: 768px) {
  .task-item-container {
    .task-item-img-content {
      .task-item-content {
        .description {
          width: 210px;
        }
      }
    }
  }
}

@media (min-width: 960px) {
  .task-item-container {
    .task-item-img-content {
      .task-item-content {
        & h4 {
          width: 200px;
        }

        .description {
          width: 220px;
        }
      }
    }
  }
}

@media (min-width: 1050px) {
  .task-item-container {
    .task-item-img-content {
      .task-item-content {
        & h4 {
          width: 210px;
        }

        .description {
          width: 230px;
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .task-item-container {
    .task-item-img-content {
      .task-item-content {
        & h4 {
          width: 220px;
        }

        .description {
          width: 240px;
        }
      }
    }
  }
}

@media (min-width: 1440px) {
  .task-item-container {
    .task-item-img-content {
      .task-item-content {
        & h4 {
          width: 250px;
        }

        .description {
          width: 300px;
        }
      }
    }
  }
}

@media (min-width: 1920px) {
  .task-item-container {
    max-width: initial;

    .task-item-img-content {
      max-width: initial;

      .task-item-content {
        & h4 {
          width: 100%;
        }

        .description {
          width: 100%;
        }
      }
    }
  }
}
