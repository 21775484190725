@import "varibals";

.header-ledger-content-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #fff;

  .header-ledger-post-commit-form {
    padding: 2px 0 3px !important;
  }

  .send.icon {
    font-size: 25px !important;
  }

  .icon.button {
    background-color: initial;
    color: #555;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: 22px !important;
    }

    &:hover {
      color: $predStatusBlue;
    }
  }

  & input {
    border-bottom-style: hidden !important;
    border-top-style: hidden !important;
    border-right-style: hidden !important;
    border-left-style: hidden !important;
    font-size: 14px !important;
    color: rgba(0, 0, 0, 0.9);
    padding: 21px 14px !important;

    &:focus::placeholder {
      color: rgba(0, 0, 0, 0.9) !important;
    }

    &::placeholder {
      color: #7f7f7f !important;
    }
  }

  & .ui.action {
    flex-direction: row-reverse;
  }
}

.header-ledger-list-container {
  padding: 0px 15px;
  overflow: auto;
  max-height: calc(100vh - 250px);
}

.header-ledger-list {
  padding-bottom: 30px;

  a {
    color: #797979;
  }
}

.header-ledger-list-item-description {
  display: flex;
  align-items: flex-end;
  //border: 1px solid transparent;
  //border-radius: 10px;
  //box-shadow: 0px 3px 8px 1px rgba(26, 26, 26, 0.35);
  //margin: 5px;
}

.header-ledger-list-item {
  display: flex;
  align-items: flex-end;
  padding: 5px;
  border: 1px solid transparent;
  margin-right: 10px;
  border-radius: 10px;
  box-shadow: 0px 3px 8px 1px rgba(26, 26, 26, 0.35);
  margin: 5px;
  //background-color: $colorHover;

  &.background-darkgrey {
    background-color: #000000b8;
  }

  .header-ledger-icon {
    padding: 0 15px 0 10px;
  }

  .header-ledger-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    //background-color: #000000b8;
  }

  .flow-ledger-history-list-text {
    color: inherit;
  }
}

.flow-ledger-timestamp {
  color: #797979;
}

.header-ledger-title {
  font-weight: 900;
  margin: 5px 0 0 15px !important;
  // font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-family: "Roboto", sans-serif;
  color: rgba(0, 0, 0, 0.87);
}

.ui.horizontal.divider {
  display: flex;
  justify-content: center;
  padding-top: 15px;
  border-top: 1px solid #d4d4d4;
  margin-top: 0;

  &:before,
  &:after {
    display: none;
  }
}
