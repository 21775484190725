@import '../../../../styles/varibals.scss';

.ChooseProject {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 260px;

    .BtnCreateProject {
        color: #fff;
        font-size: 14px;
        background-color: $predStatusBlue;
        padding: 17px 25px;
        border-radius: 3px;
        max-width: 160px;
        text-align: center;
        margin: 20px 0;

        &:hover {
            color: $predStatusBlue;
            background-color: #fff;
            border: 1px solid $predStatusBlue;
        }
    }
}