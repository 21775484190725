@import "src/styles/varibals";

.Icon {
  color: inherit;
  font-size: 24px;

  &.GreenIcon {
    color: $predStatusGreen;
  }

  &.YellowIcon {
    color: $predStatusYellow;
  }

  &.RedIcon {
    color: $predStatusRed;
  }

  &.DarkIcon {
    color: $colorMidDark !important;
  }
}

.Complete {
  width: 10px;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $predStatusBlue;
  color: white;
  border-radius: 50%;
}

.Block {
  font-size: 10px;
}

.NotStarted {
  svg {
    font-size: 10px;
  }
}

.Blinking {
  animation: blinking-anim 1.2s infinite;
}

@keyframes blinking-anim {
  45% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.2;
  }
}
