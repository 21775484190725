
.GoogleMapContainer {
  width: 100%;
  height: 400px;
  margin-bottom: 5px;
}

.GoogleMapContainerResize {
  width: 100%;
  height: 3px;
  background-color: rgba(203, 200, 201, 0.44);
  cursor: row-resize;
  z-index: 201;
  padding-top: 2px;
  padding-bottom: 2px;
  &:hover {
    background-color: rgba(24, 85, 208, 0.8);
  }
}


.MapFilterContainer {
  position: absolute;
  top: 10px;
  right: 100px;
  background-color: rgb(255, 255, 255, 0.6);
  padding: 7px 15px 7px 15px;
  border-radius: 5px;
}

.MapFilterDatePicker {
  max-width: 100px;
  min-width: 100px;
  width: 100px;
  color: #737272;
}

.MapFilterDatePickerChanged {
  max-width: 100px;
  min-width: 100px;
  width: 100px;
  background-color: #d9f3e6;
  color: black
}

.MapFilterDatePickerContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.MapFilterButton {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  padding: 4px 7px 4px 7px;
  font-size: 1rem;
  cursor: pointer;
  margin-left: 7px;
  &:hover {
    background-color: #cacbcd;
  }
}

.MapFilterSearchBar {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  margin-top: 5px;
  max-height: 31px;
}

.MapFilterResetButton {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  padding: 4px 7px 4px 7px;
  font-size: 1rem;
  cursor: pointer;
  margin-left: 3px;
  &:hover {
    background-color: #cacbcd;
  }
}