@import "../../../../../../styles/varibals";

.CalendarsWrapper {
  padding: 10px 35px;
  overflow: auto;
  max-height: calc(100vh - 210px);

  // button.react-calendar__month-view__days__day--weekend {
  //    color: $colorLight !important;
  // }

  .CalendarWrapper {
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    .CalendarHeader {
      font-size: 16px;
      color: $colorThird;
    }

    .CustomizedCalendar {
      border: none;
      font-size: 16px;

      abbr {
        text-decoration: none;
      }
    }

    .TodayHighlighted {
      font-weight: bold;
    }

    .NonWorkingDay {
      background-color: transparent !important;
      cursor: auto;
    }

    .IncludedSchedule {
      background-color: $colorHover;
      //&:disabled {
      //    opacity: 0.3;
      //}
    }

    .Hover {
      &:hover {
        background-color: rgba(180, 180, 180, 0.431) !important;
        color: white !important;
      }
    }

    .DisabledTile {
      color: $colorLight;
    }

    // .PinnedDate {
    //     position: relative;
    //     &::before {
    //         display: inline-block;
    //         font-style: normal;
    //         font-variant: normal;
    //         text-rendering: auto;
    //         -webkit-font-smoothing: antialiased;
    //         position: absolute;
    //         font-family: "Font Awesome 5 Pro";
    //         font-weight: 900;
    //         content: "\f08d";
    //         top: 0;
    //         right: 0;
    //     }
    // }

    .PinnedDateIcon {
      position: absolute;
      top: 1px;
      right: 3px;
      z-index: 100;
      transform: rotate(45deg);
    }

    .PinTrigger {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 100;
      top: 0;
      left: 0;
    }

    .ActiveDate {
      background-color: $predStatusBlue;
    }
  }
}

.NoActiveTask {
  text-align: center;
  padding: 10px;
  font-size: 16px;
  color: $colorSecondary;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: bold;
}

.HeaderWrap {
  display: flex;
  padding: 13px 15px;
  color: $colorSecondary;
  font-size: 12px;
  position: relative;
}

.HeaderSchedule {
  display: flex;
  flex: 1;
}

.HeaderScheduleItem {
  flex: 1;
  padding-right: 10px;

  &:last-child {
    padding-right: 0px;
  }

  .Label {
    padding-right: 5px;
  }
}

.HeaderCalendarDescription {
  // text-transform: uppercase;
  font-size: 9px;
  line-height: 20px;
  color: #000;
  text-align: center;
  white-space: nowrap;
  font-weight: bold;
  padding: 7px 0;
  border-bottom: 1px solid #e3e3e3;

  svg {
    font-size: 20px;
  }

  p {
    font-size: 1rem;
    font-weight: 900;
    // font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
    font-family: "Roboto", sans-serif;
    color: $backgroundPrimary;
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 0 15px;

    svg {
      margin-right: 10px;
    }
  }

  .schedule {
    display: flex;
  }

  .content {
    .ContentItem {
      padding: 10px 15px 10px 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      flex-direction: column;

      .subTitle {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        div {
          display: flex;

          svg {
            font-size: 20px;
            width: 20px;
          }
        }
      }

      .SubContentItem {
        display: flex;
        margin-top: 10px;
        padding-left: 30px;
        text-transform: none;
        width: 100%;
        flex-direction: column;

        div {
          display: flex;
          justify-content: space-between;
          padding: 10px 0;
        }

        .subDescription {
          font-size: 14px;
          font-weight: 400;
          margin: 0;
        }
      }

      &.active {
        background-color: rgba(203, 230, 255, 0.7);

        h4 {
          font-weight: bold;
        }

        &:hover {
          background-color: rgba(203, 230, 255, 0.7);
        }
      }

      &:hover,
      &:focus,
      &:active {
        background-color: rgba(203, 230, 255, 0.4);

        h4 {
          font-weight: bold;
        }
      }
    }

    .active {
      background-color: rgba(203, 230, 255, 0.7);
    }

    h4 {
      margin: 0;
      padding: 0 15px;
      font-size: 14px;
      font-weight: 400;
      text-transform: none;
    }
  }

  // .Delivery {
  //     flex: 1;
  //     color: $colorDark;
  //     background-color: #daedff;
  // }
  // .Forecast {
  //     flex: 1;
  //     background-color: $predStatusGreen;
  // }

  // .Actual {
  //     flex: 1;
  //     background-color: $predStatusBlue;
  // }
}

.GreenStatus {
  background-color: $predStatusGreen !important;
  color: #fff !important;
}

.YellowStatus {
  background-color: $predStatusYellow !important;
  color: #fff !important;

  //&:disabled {
  //  opacity: 0.3;
  //}
}

.RedStatus {
  background-color: $predStatusRed !important;
  color: #fff !important;
}

.BlueStatus {
  background-color: $predStatusBlue !important;
  color: #fff !important;
}

.BaselineClass {
  border: 2px solid black !important;
  color: black !important;
}

.GreenClass {
  background-color: $predStatusGreen !important;
  color: #fff !important;
}

.YellowClass {
  background-color: $predStatusYellow !important;
  color: #fff !important;
}

.RedClass {
  background-color: $predStatusRed !important;
  color: #fff !important;
}

.HeaderTaskCode {
  top: 0;
  right: 5px;
  font-size: 10px;
}

.SwitchContainer {
  display: flex;
  align-items: center;
  gap: 10px;

  .SwitchTitle {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: #7f7f7f;
    cursor: default;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
  margin-top: 1px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0f9d58;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
  &:before {
    border-radius: 50%;
  }
}

.slider:before {
  position: absolute;
  content: "";
  height: 11px;
  width: 11px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #db4437;
}

input:focus + .slider {
  box-shadow: 0 0 1px #db4437;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}
