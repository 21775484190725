@import "src/styles/varibals";

.SimulationModelForm {
    min-width: 90%;
    background-color: #f3f4f5;
    border-radius: 10px;
    padding: 5px;

  .ThisFormField {
    margin-top: 20px;
    padding-top: 10px;
    padding-left: 10px;
  }
}

.IterationsForm {
  margin-top: 10px;
  min-width: 90%;
  background-color: #f3f4f5;
  border-radius: 10px;
  padding: 5px;
}

.UncertaintyForm {
  margin-top: 10px;
  min-width: 90%;
  background-color: #f3f4f5;
  border-radius: 10px;
  padding: 5px;
}

