@import "../../../../../../styles/varibals.scss";

.NotificationAccordion {
  width: 100%;
  .Header {
    display: flex !important;
    justify-content: center;
    border-bottom: 0 !important;
    padding: 30px 0 !important;
  }

  .accordion-title {
    width: 100%;
  }

  .ContentItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    margin-bottom: 10px;

    .Title {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 14px;
      color: #000;
      margin-bottom: unset;

      svg {
        font-size: 20px;
        margin-right: 1rem;
        height: 20px;
        width: 25px;
      }

      span {
        color: rgba(0, 0, 0, 0.87);
        // font-family: "Open Sans", sans-serif !important;
        font-family: "Roboto", sans-serif;
        font-weight: 600;
        font-size: 14px !important;
      }
    }

    .Title.TitleMain {
      font-weight: 700;
      opacity: 1 !important;
      font-size: 16px;
      color: #000;
    }

    .ButtonWrapper {
      border-radius: 20px;
      background-color: #fff;
      border: 1px solid $backgroundActive;

      .Button {
        border: 0;
        box-shadow: 0 !important;
        margin-right: 0;
        border-radius: 0;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        background-color: transparent;

        &:last-child {
          border-radius: 0;
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;
        }
      }

      .ActiveButton {
        background-color: $predStatusBlue;
        color: #fff;
      }
    }

    .Checkbox {
      min-height: 20px;
      line-height: 20px;
      min-width: 20px;

      input:focus:checked ~ label::before {
        background-color: #000 !important;
      }

      input:checked ~ label::before {
        background-color: #000 !important;
      }
      label:before {
        width: 20px;
        height: 20px;
        border-radius: 10px;
        border: 1px solid #000 !important;
      }
    }
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
  margin-top: 1px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
  &:before {
    border-radius: 50%;
  }
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}
