@import "src/styles/varibals";

.GanttChartContainer {
  min-width: 100%;

  .DatePicker {
    width: 100%;
  }

  .HeaderRow {
    position: sticky;
    top: 0;
    padding: 0;
  }

  .HeaderCell {
    border-right: #d0cece solid 1px;
    background-color: #f5f3f3;
  }

  .WBSHeaderContainer {
    display: flex;
    align-items: center;
    flex-direction: row;
    font-weight: bold;
    font-size: 15px;
  }

  .TaskRow:hover {
    background-color: $colorLightHover;
    .PredButton {
      color: rgb(0, 0, 0, 0.3);
    }
    .SuccButton {
      color: rgb(0, 0, 0, 0.3);
    }
  }

  .ActiveTask {
    background-color: $colorHover;
    &:hover {
      background-color: $colorHover;
    }
  }

  .TaskNameContainer {
    display: flex;
    align-items: center;
    flex-direction: row;
    position: relative;
  }

  .ExpandIcon {
    margin-right: 10px;
    cursor: pointer;
    margin-left: 5px;
  }

  .GanttChartTable {
    max-width: 100%;
    min-width: 100%;
    padding-bottom: 10px;
  }

  .GanttChartCellContainer {
    border-bottom: 1px solid #b2b1b1;
    height: 100%;
    display: flex;
    flex-direction: row;
    overflow: hidden;
  }

  .GanttChartCellPast {
    background-color: rgba(201, 201, 201, 0.5);
    min-height: 29px;
    height: 100%;
    border-right: $predStatusRed solid 4px;
    overflow: hidden;
  }

  .GanttChartCellFuture {
    //background-color: #0f9d58;
    min-height: 29px;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .WBSHeaderRow {
    &:hover {
      background-color: $colorLightHover;
    }
  }

  .SummaryBarPast {
    min-height: 10px;
    background-color: #2d2d2d;
    position: relative;
    top: calc(50% - 5px);
    //border-right: $predStatusRed solid 2.5px;
    border-radius: 2px 0 0 2px;
  }

  .SummaryBarTotalPast {
    min-height: 10px;
    background-color: #2d2d2d;
    position: relative;
    top: 10px;
    border-radius: 2px;
  }

  .SummaryBarFuture {
    min-height: 10px;
    background-color: #2d2d2d;
    position: relative;
    top: calc(50% - 5px);
    border-radius: 0px 2px 2px 0px;
  }

  .SummaryBarTotalFuture {
    min-height: 10px;
    background-color: #2d2d2d;
    position: relative;
    top: 10px;
    border-radius: 2px;
  }

  .SummaryBarMilestone {
    transform: rotate(45deg);
    min-height: 12px;
    max-height: 12px;
    min-width: 12px;
    max-width: 12px;
    background-color: #2d2d2d;
    position: relative;
    top: 10px;
    border-radius: 2px;
  }

  .TaskBarMilestone {
    transform: rotate(45deg);
    min-height: 12px;
    max-height: 12px;
    min-width: 12px;
    max-width: 12px;
    background-color: #2d2d2d;
    position: relative;
    top: 10px;
    //border: #3264b7 solid 2px;
    border-radius: 2px;
  }

  .TaskBarMilestoneWithBaseline {
    transform: rotate(45deg);
    min-height: 12px;
    max-height: 12px;
    min-width: 12px;
    max-width: 12px;
    background-color: #2d2d2d;
    position: relative;
    top: 6px;
    //border: #3264b7 solid 2px;
    border-radius: 2px;
  }


  .TaskBarPast {
    min-height: 14px;
    background-color: $predStatusBlue;
    position: relative;
    top: calc(50% - 7px);
    //border-right: $predStatusRed solid 2.5px;
    border-radius: 4px 0 0 4px;

    &:hover {
          mask: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
        }
  }

  .TaskBarTotalPast {
    min-height: 14px;
    background-color: $predStatusBlue;
    position: relative;
    top: calc(50% - 7px);
    border-radius: 4px;

    &:hover {
      mask: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    }
  }

  .TaskBarFuture {
    min-height: 14px;
    background-color: $predStatusGreen;
    position: relative;
    top: calc(50% - 7px);
    border-radius: 0px 4px 4px 0px;

    &:hover {
      mask: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    }
  }

  .CriticalPath {
    background-color: $predStatusRed !important;
  }

  .TaskBarBaseline {
    min-height: 5px;
    background-color: lightgray;
    border-radius: 2px;
    position: relative;
  }

  .BaselineMilestone {
    transform: rotate(45deg);
    min-height: 8px;
    max-height: 8px;
    min-width: 8px;
    max-width: 8px;
    background-color: rgba(157, 156, 156, 0.76);
    position: relative;
    //border: #3264b7 solid 2px;
    border-radius: 2px;
  }

  .TaskBarTotalFuture {
    min-height: 14px;
    background-color: $predStatusGreen;
    position: relative;
    top: calc(50% - 7px);
    border-radius: 4px;

    &:hover {
      mask: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    }
  }

  .CompleteSymbol {
    color: $predStatusGreen;
    margin-right: 10px;
    font-size: 18px;
    cursor: w-resize;
  }

  .WBSStartDateCell {
    border-right: #d0cece solid 1px;
    font-weight: bold;
    font-size: 14px;
  }

  .WBSFinishDateCell {
    font-weight: bold;
    font-size: 14px;
  }

  .WBSNameCell {
    border-right: #d0cece solid 1px;
  }

  .TaskStartDateCell {
    border-right: #d0cece solid 1px;
    font-size: 14px;
  }

  .TaskFinishDateCell {
    font-size: 14px;
  }

  .TaskNameCell {
    border-right: #d0cece solid 1px;

    .BlueLineDiv {
      position: absolute;
      width: 100vw;
      height: 3px;
      background-color: $predStatusBlue;
      bottom: -5px;
    }

    .AddTaskButton {
      padding: 5px;
      position: absolute;
      left: 20px;
      margin: 0;
      bottom: -15px;
      border-radius: 50%;

      &:hover {
        //border: 3px solid $predStatusBlue;
        background-color: rgba(66, 133, 244, 1);
        color: white;
        bottom: -17px;
        font-size: 16px;
      }
    }
  }

  .HoveredHeading {
    background-color: $colorLightHover;
  }
}

.PredButton {
  color: rgb(0, 0, 0, 0);
  cursor: pointer;
  padding-right: 20px;
  position: absolute;
  left: 5px;

    &:hover {
        color: rgb(0, 0, 0, 0.7) !important;
    }
}

.SuccButton {
  color: rgb(0, 0, 0, 0);
  cursor: pointer;
  padding-left: 20px;
  position: absolute;
  right: 5px;

  &:hover {
    color: rgb(0, 0, 0, 0.7) !important;
  }
}

.DatePopup {
  background-color: white;
  z-index: 400;
  border-style: solid;
  border-width: 1px;
  border-color: #a4a4a4;
  border-radius: 10px;
  max-width: 175px;
  min-width: 175px;
  max-height: 50px;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

 .PopupDate {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   min-width: 100%;
   > p {
     margin-left: 5px;
     margin-right: 5px;
     margin-bottom: 0;
     padding: 0;
   }
 }
}

.TaskInfoContainer {
  position: sticky;
  bottom: 0;
  //background-color: white;
  background-color: #f5f3f3;
  z-index: 310;
  min-height: 325px;
  min-width: 100vw;
  // give me a box shadow only at the top
    box-shadow: 0 -5px 5px -5px #333;
}

.TaskDetail {
  margin: 5px;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.TaskDetailSmall {
  margin: 5px;
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 250px;
}


.TaskDetailRow {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  //padding: 10px;
  border-bottom: 1px solid #d0cece;
}

.TaskRelationshipsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  //padding: 10px;
  border-bottom: 1px solid #d0cece;
}

.RelationshipListContainer {
  flex: 1;
    display: flex;
  min-height: 200px;
  border: 1px solid #d0cece;
  border-radius: 5px;
}

.RelationshipList {
  flex: 1;
  table-layout: auto;
  width: 100%;
  //border-style: solid;
}

.RelationshipRow {
  display: flex;
  flex-direction: row;
  padding: 5px;
  border-bottom: 1px solid #d0cece;
}

.RelationshipHeaderCell {
  flex: 1;
}

.RelationshipHeaderCellSmall {
  width: 60px;
}

.RelationshipCell {
  flex: 1;
  max-height: 20px;
}

.RelationshipCellSmall {
  width: 60px;
  max-width: 60px;
  overflow: hidden;
}

.GanttChartPageDiv {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  min-height: calc(100vh - 140px);
  max-height: calc(100vh - 140px);
}