.member-grade-label {
  margin-bottom: 15px;

  span {
    padding-left: 10px;
  }
}

.member-status-actor {
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 80px;
  }

  svg {
    width: 1.3rem !important;
    height: 1.3rem;
  }
}


.member-grade-status-color {
  tr > th, td {
    &:nth-child(3) {
      background-color: rgba(215, 194, 0, 0.25) ;
    }

    &:nth-child(4) {
      background-color: rgba(215, 143, 0, 0.38);
    }

    &:nth-child(5) {
      background-color: rgba(45, 45, 45, 0.38);
    }
  }

  tr {
    &:last-child {
        td {
          background-color: #cccccc;
          opacity: 0.4;
          transition: .2s;
          &.active {
            opacity: 1 !important;
            color: #0d71bb  !important;;
            font-size: 24px;
          }
        }
    }
  }
}


.member-grade-status-agent {
  background-color: rgba(215, 194, 0, 0.25) !important;
}

.member-grade-status-prime-agent {
  background-color: rgba(215, 143, 0, 0.38) !important;
}

.member-grade-status-uber-agent {
  background-color: rgba(45, 45, 45, 0.38) !important;
}
