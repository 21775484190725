@import "varibals";

.search-bar-container {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0;

  .search-bar-filter-wrap {
    display: flex;
    align-items: center;
    flex: initial;
    width: 100%;
    border-bottom: 1px solid $backgroundLight;
  }

  .search-bar-filter-item {
    flex: initial;

    .search-bar-item.my-tasks-checkbox {
      position: relative;

      .icon-checkbox-wrapper {
        position: absolute;
        bottom: 75%;
        left: 30px;
        z-index: 20;
        background-color: yellow;
        padding: 3px;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        .icon-checkbox {
          font-size: 10px;
          padding: 0;
          margin: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .ui.action.full-width {
      display: flex;
      input {
        order: 2;
        background-color: transparent;
        border-bottom-style: none;
        border-top-style: none;
        border-right-style: none;
        border-left-style: none;
        font-size: 14px;
        padding: 10px 20px !important;
        padding: 0 !important;
        &::placeholder {
          color: #333;
        }
      }

      button {
        background-color: transparent;
        i {
          font-size: 25px;
        }
      }
    }
  }
}


.search-bar-item {
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.report-dropdown-item {
  display: flex;
  align-items: center;
  padding: .78571rem 1.14286rem;
}

.report-dropdown-item:hover{
  background-color: #eee;
  cursor: pointer;
}
.report-dropdown-item-label {
  cursor: pointer;
  flex: 1;
}

.search-bar-chips-wrap {

  &:first-child {
    margin-left: 5px;
  }
  .label {
    margin: 5px;
  }

  .filter {
    background-color: $backgroundLight !important;
  }

  .report {
    background-color: $colorLight !important;
  }
}

.search-bar-filter-wrap {
  flex: 1;
  display: flex;
  flex-flow: wrap;
}

.search-bar-filter-item {
  display: flex;
  margin: 0 10px 10px 0;
}

.my-tasks-checkbox {
  min-width: 123px;

  .checkbox {
    label {
      font-weight: 700;
      color: rgba($color: #000000, $alpha: 0.6) !important;
    }
  }
}

.search-bar-input-wrap {
  min-width: 265px;
  //min-height: 45px;
}
