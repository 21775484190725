@import '../../../../../styles/varibals.scss';

.ActiveReport {
    color: #fff !important;
	background-color: #000000d1 !important;
}

.ReportsDropdown{
    position: absolute;
    opacity: 0;
    background-color: #fff;
    font-size: 1em;
    text-shadow: none;
    text-align: left;
    box-shadow: 0 2px 3px 0 rgba(34, 36, 38 , .15);
    border: 1px solid rgba(34,36,38,.15);
    border-radius: .28571429rem;
    transition: opacity .1s ease;
    z-index: 11;
    display: none;
}

.ReportsDropdown.Active{
    opacity: 1 !important;
    display: block;
}

.ReportsDropdown button {
    height: unset !important;
}

