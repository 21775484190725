@import "src/styles/varibals";

.MemberItemWrap {
  display: flex;
  margin-bottom: 20px;
  cursor: pointer;
  // color: $colorPrimary;
  position: relative;
  align-items: center;

  svg {
    flex-basis: 15%;
  }
  .fa-balance-scale {
    flex-basis: 5%;
  }
}

.MemberEmailLabel {
  margin-right: 10px;
  display: inline-block;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 150px;
  margin-left: 2px;
  // font-family: "Open Sans", sans-serif !important;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 14px !important;
  color: rgba(0, 0, 0, 0.95) !important;
}

.active {
  .MemberEmailLabel {
    font-weight: 700 !important;
  }
}

.MemberItemWrapPopup {
  padding: 0 !important;
  width: 0;
  height: 0;

  &:before {
    display: none;
  }
}

.MemberPendingLabel {
  position: absolute;
  color: yellow !important;
  background-color: rgba(0, 0, 0, 0.9) !important;
  width: 20px;
  height: 20px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  left: -20px;
}

// .MemberItemIcon {
//   margin-right: 0px;
// }

.MembersCountOfTask {
  margin-right: 5px;
  font-weight: normal;
  position: absolute;
  left: 20px;
  top: -5px;
  background-color: #daedff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #555;
}

.NoTasksIcon {
  font-size: 90px;
  color: #e0f2f9;
  margin-bottom: 10px;
}
