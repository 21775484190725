$colorPrimary: #8f8f8f;
$colorDarker: #212121;
$colorMidDark: #333333;
$colorDark: #5c5c5c;
$colorLight: #d7d7d7;
$colorSecondary: #7f7f7f;
$colorThird: #555;
$colorFourth: #e6e6e6;

$backgroundMainDark: #212121;
$backgroundDark: #484643;
$backgroundLight: #f8f8f8;
$backgroundMain: #ffffff;
$backgroundError: #cc494a;
$backgroundPrimary: #333333;
$backgroundLightGrey: #aaaaaa;
$backgroundActive: #f2f2f2;
$predStatusBlue: #4285f4;
$predStatusRed: #d9001b;
$predStatusGreen: rgb(15, 157, 88);
$predStatusYellow: #f4b400;
$colorHover: rgba(203, 230, 255, 0.7);
$colorLightHover: rgba(241, 248, 255, 0.7);
$sliderButtonHeight: 18px;

$borderRadiusButtonDefault: 0.28571429rem;
