.last-updated-wrap {
  white-space: nowrap;
}

.sc-dnqmqq.ikKGsF {
  overflow: hidden;

  .sc-ifAKCX.GNPXT {
    overflow: hidden;
  }
}

.project-summary-common {
  display: flex;
  justify-content: space-between;
  // border-bottom: 1px solid rgba($color: #333, $alpha: 0.3);
  padding: 10px 30px;
  min-height: 50px;
  box-shadow: 0px 9px 14px -5px rgba(83, 83, 83, 0.52);
  overflow: hidden;
}

@keyframes fadeWbsName {
  from {
    opacity: 0;
  }
  to {
    opacity: 1 !important;
  }
}

.project-summary-title {
  margin: 0 !important;
  display: flex;
  align-items: center;
  flex: 1;
  overflow: hidden;

  .fade-wbs {
    animation-name: fadeWbsName;
    opacity: 0;
    animation-duration: 0.1s;
    animation-timing-function: ease-out;
    animation-delay: 0.1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    font-size: 18px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 1.5 !important;
  }
}