@import "src/styles/varibals";


.WbsTree {
  padding-top: 10px;
}

.WbsListItem {
  margin-bottom: -5px;
}

.WbsTreeModal {

}

.WbsHeading {
  padding: 0 5px;
  cursor: pointer;
  border-bottom: 1px solid $backgroundActive;
  // add background color for hover effect
    &:hover {
        background-color: $colorLightHover;
    }
}

.WbsTreeContainer {
  margin-left: 15px;
  padding-top: 20px;
  max-height: 80vh;
  min-height: 80vh;
  overflow-y: auto;
}

.ExpandCollapseIcon {
    cursor: pointer;
}