@import "src/styles/varibals";

.Container {
  display: flex;
  justify-content: space-between;
  padding: 17px 15px;
  padding-bottom: 0px;
}

.FilterItem {
  margin-right: 10px;
  margin-bottom: 10px;


  &:last-child {
    margin-right: 0;
  }
}

.FilterItem:nth-of-type(1) {
  button {
    background-color: #fff;
  }
}

.FilterItem:nth-of-type(2) {
  button {
    background: #fff;
  }
}
.FilterItem:nth-of-type(3) {
  button {
    background: #fff;
  }
}
.FilterItem:nth-of-type(4) {
  button {
    background: #fff;
  }
}

.FilterItem:nth-of-type(7) {
  button {
    background: #fff;
  }
}

.FilterItem {
  button {
    background: #fff;
  }
}

.FilterItem:nth-of-type(5) {
  margin: 0 10px;
  button {
    border: 1px solid rgba(0, 0, 0, 0.6);
    background: #fff;
    height: 30px;
    padding: 0 0.45em;
    font-weight: 400;
    font-size: 14px !important;
  }

  button:hover,
  button:focus {
    background: #cacbcd !important;
  }

  button.active {
    background: #c0c1c2 !important;
  }
}

.ui.action.input > .button {
  height: unset !important;
}

.SearchBar {
  overflow: hidden;
  width: 36px;
  border-radius: 20px;
  transition: width 0.2s;
  position: relative;
  margin-left: 10px;
  display: flex;
  flex-direction: row;

  button {
    padding: 0.45em !important;
    width: 30px;
    height: 30px;
    border-radius: 50% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.6) !important;
    background: #fff !important;
  }

  .ActiveFilterSearchButton {
    color: #fff !important;
    background-color: $colorMidDark !important;
  }

  button:hover {
    background: #cacbcd !important;
  }

  button.active {
    background: #c0c1c2 !important;
  }

  input {
    padding: 5px !important;
    opacity: 0;
    font-size: 14px !important;
  }

  input:focus {
    border-color: rgba(0, 0, 0, 0.95) !important;
  }

  &.Expanded {
    width: 250px;
    flex-shrink: 1;

    button {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }

    input {
      opacity: 1 !important;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }

  :global {
    //.ui.form input[type="text"] {
    //  border-radius: 20px 0 0 20px !important;
    //}

    .ui[class*="left action"].input > input:focus {
      border-left-color: unset !important;
    }
  }
}

.Wrapper {
  display: flex;
  width: 100%;
}

.InputWrapper {
  display: flex !important;
  border-radius: 0 !important;
  button {
    font-size: 1.3rem !important;
  }
}

.CalendarSearch {
  font-size: 1.3rem !important;
  width: 33px;
  height: 30px;
  border: 1px solid;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  border-radius: 0 50% 50% 0!important;
  color: $colorDark !important;

    &:hover {
        background: #cacbcd !important;
    }
}

.DateRangeFilter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  //border: 1px solid rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  font-size: 14px !important;
  font-weight: 400 !important;
  min-height: 40px;
  max-height: 40px;
  margin-top: -5px;
  color: $colorDark;
  background-color: #e8e8e8;
}

.CalendarSearchButton {
  margin-right: 4px;
}

.ActiveFilterButton {
  color: #fff !important;
  background-color: $colorMidDark !important;
  border: 0.5px solid $colorMidDark !important;
}

.ActiveFilterChip {
  color: #fff !important;
  background-color: $colorMidDark !important;
  border: 0.5px solid $colorMidDark !important;
  border-radius: 100px !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  height: 30px;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.ActiveFilterSearchInput {
  border-radius: 0;

  input {
    border: 0 !important;
    color: #fff !important;
    background-color: $colorMidDark !important;

    &:focus {
      border-color: transparent !important;
      border-left-color: transparent !important;
      border: 0 !important;
    }
  }

  button {
    border: 0 !important;
  }
}

.DaysBanked {
  margin-left: auto;
  padding-right: 40px;
  font-weight: bold;
  font-size: 16px;
  color: $colorDark;
}

.View {
  width: 20%;
  display: flex;
  justify-content: flex-end;
}

.BtnCloseView {
  border-radius: 20px !important;
  background-color: $colorMidDark !important;
  color: #fff !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  height: 30px;
  padding: 7px 1.5em !important;
  min-width: 165px;

  &:hover {
    opacity: 0.9;
  }

  span {
    padding-left: 15px;
  }
}

.Tools {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 80%;

  & input {
    flex-shrink: 1 !important;
    min-width: 0px !important;
    flex-grow: 1 !important;
  }
}

.ButtonCalendar {
  span {
    position: relative;
  }
}

.BetaButton {
  text-align: center !important;
  justify-content: center !important;
  align-items: center !important;
  display: flex !important;
  flex-direction: column !important;
  color: $colorDark !important;
}

.BetaContainer {
  position: absolute !important;
  min-height: 45px;
}

.BetaTag {
  font-size: 9px;
  font-weight: bold;
  color: #fff;
  background-color: $predStatusBlue;
  opacity: 90%;
  position: relative;
  padding: 1px 3px 0px 3px;
  z-index: 400;
}

.BetaText {
  position: absolute;
  font-size: 8px;
  font-weight: bold;
  color: #fff;
  background-color: $predStatusBlue;
  top: -5px;
  left: 7px;
  width: 30px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.InteractionBar {
  display: flex;
  z-index: 1;

  button {
    width: 30px;
    height: 30px;
    border-radius: 50% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px !important;
    margin-right: 0 !important;
    border: 1px solid rgba(0, 0, 0, 0.6) !important;
    background: #fff !important;
    padding: 0 !important;
    font-size: 1.3rem !important;

    &:active {
      color: #fff !important;
      background: $colorMidDark !important;
    }
  }

  button:hover,
  .FilterButton:hover {
    background: #cacbcd !important;
  }

  .ButtonCalendar {
    color: #fff !important;
    background: $colorMidDark !important;
  }

  .ButtonMember {
    color: #fff !important;
    background: $colorMidDark !important;
  }

  .ButtonLedgerActive,
  .ButtonLedger {
    color: #fff !important;
    background: $colorMidDark !important;
  }
}

.button-ledger-with-active {
  color: #fff !important;
  background: $colorMidDark !important;
}

.button-ledger {
  color: #fff !important;
  background: $colorMidDark !important;
}

.FilterButton {
  width: 30px;
  height: 30px;
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px !important;
  margin-right: 0 !important;
  border: 1px solid rgba(0, 0, 0, 0.6) !important;
  padding: 0 !important;
  font-size: 1.3rem !important;
}

.AddButton {
  width: 30px;
  height: 30px;
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px !important;
  margin-right: 0 !important;
  border: 1px solid rgba(0, 0, 0, 0.6) !important;
  background: #fff !important;
  padding: 0 !important;
  font-size: 1.3rem !important;
}

.QualityButtonBackground {
  background: #fff !important;
}

.QualityButtonBackground:hover {
  background: #cacbcd !important;
}

.AddButton:hover {
  background: #cacbcd !important;
}

.BreadcrumbBar {
  padding-left: 20px;
  padding-bottom: 5px;
  border-bottom: 2px solid #e5e5e5;
  display: flex;

  span {
    padding-left: 4px;
  }
}

.RecentUserItems {
  display: flex;
  flex-direction: column;

  span {
    padding: 5px;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    border-bottom: 1px solid #e5e5e5;
    cursor: pointer;
    border-radius: 8px;
    p {
        margin: 0;
    }
    &:hover {
      background: #f8f7f7;
    }
  }
}

.SwitchContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 15px;
  padding-top: 7px;
  margin-right: 10px;

  .SwitchTitle {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #7f7f7f;
    cursor: default;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
  margin-top: 1px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #989898;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
  &:before {
    border-radius: 50%;
  }
}

.slider:before {
  position: absolute;
  content: "";
  height: 11px;
  width: 11px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #0f9d58;
}

input:focus + .slider {
  box-shadow: 0 0 1px #0f9d58;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

.HoverTooltip {
  font-family: "Roboto", sans-serif;
  position: fixed;
  padding: 3px 8px;
  font-size: 12px;
  background: #7d7d7e;
  opacity: 0.9;
  color: white;
  z-index: 350;
  border-radius: 5px;
  max-height: 30px;
  white-space: nowrap;
  text-overflow: ellipsis;
}