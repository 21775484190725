@import "~semantic-ui-css/semantic.min.css";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@import "~font-awesome/css/font-awesome.min.css";
@import "./styles/loader";
@import "./styles/varibals.scss";
@import "./styles/navigation-bar.scss";
@import "./styles/search-bar.scss";
@import "./styles/project-summary.scss";
@import "./styles/flow-page.scss";
@import "./styles/ledger";
@import "./styles/members";
@import "./styles/mamber-grade-modal";
@import "./styles/project";
@import "./styles/delete-project-page.scss";
@import "~react-virtualized/styles.css";
@import "./styles/calendar.scss";

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // display: flex;
  //flex-direction: column;
  display: block;
  overflow-y: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ui.form input[type="text"] {
  width: initial;
}

#root {
  display: flex;
  flex: 1;
}

.dimmed.dimmable > .ui.animating.dimmer,
.dimmed.dimmable > .ui.visible.dimmer,
.ui.active.dimmer {
  display: flex !important;
  opacity: 1 !important;
}

.white-text {
  color: white !important;
}

.m-0 {
  margin: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.flex-1 {
  flex: 1;
}

.transparent {
  background-color: transparent !important;
}

.full-width {
  width: 100%;
}

.m-0 {
  margin: 0;
}

.ui.label {
  background-color: $backgroundActive;
}

.sprint-page-container {
  display: flex;
  flex-flow: column;
}

.lazy-load-container {
  position: relative;
  overflow-y: auto;
  max-width: 100vw;
  overflow-x: hidden;
  flex: 1 1 auto;

  .lazy-load-content {
    padding-bottom: 70px;
    position: relative;
  }

  &.sprint {
    flex: 1;
  }

  .load-more-items-wrap {
    display: block;
    position: absolute;
    bottom: 0;
    left: calc(50% - 16px);

    .loader.active {
      top: -40px !important;
    }
  }
}

.bi-embedded {
  position: relative;
  width: 100%;
  height: calc(100vh - 80px);
}


.sprint-total-div > p {
  padding: 0;
  margin-left: 8px;
  color: $colorSecondary;
  padding-top: 2px;
}

.sprint-accordion {
  border-bottom: 1px solid $colorLight;
  display: flex;
  flex-direction: column;

  .sprint-accordion-title {
    display: flex;
    flex-direction: row;
    min-height: 50px;
    align-items: center;

    .chevron-icon {
      margin-left: 15px;
      cursor: pointer;
      font-size: 30px;
      color: $backgroundLightGrey;

      &:hover,
      &:focus,
      &:active {
        color: $predStatusBlue;
      }
    }

    .sprint-accordion-label {
      color: $backgroundPrimary;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      font-weight: 900;
      margin-left: 15px;
      // font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
      font-family: "Roboto", sans-serif;
      &:hover {
        color: $predStatusBlue;
      }
    }
}}

.sprint-switch-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 20px;

  .sprint-switch-title {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: #7f7f7f;
    cursor: default;
  }
}

.sprint-switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
  margin-top: 1px;

}

.sprint-switch-input {
  opacity: 0;
  width: 0;
  height: 0;
}

.sprint-switch-input:checked + .sprint-switch-slider {
  background-color: $predStatusBlue;
}

.sprint-switch-input:focus + .sprint-switch-slider {
  box-shadow: 0 0 1px $predStatusBlue;
}

.sprint-switch-input:checked + .sprint-switch-slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

.sprint-switch-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0f9d58;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
  &:before {
    border-radius: 50%;
  }
}

.sprint-switch-slider:before {
  position: absolute;
  content: "";
  height: 11px;
  width: 11px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.progress-total-inside {
  padding: 0;
  //margin-right: 8px;
  position: relative;
  color: $colorSecondary;
}

.progress-total-outside {
  padding: 0;
  margin-right: 8px;
  margin-bottom: 0;
  position: relative;
  flex: 1;
  color: $colorSecondary;
}

.sprint-popup {
    position: absolute;
    z-index: 1000;
    background-color: $backgroundMain;
    border-radius: 5px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    padding: 10px;
    min-width: 200px;
    max-width: 400px;
    //font-size: 14px;
    //font-family: "Open Sans", sans-serif;
    color: $colorSecondary;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.sprint-timeline-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  //border-radius: 5px 0px 0px 5px;
  overflow: hidden;
  //border: 1px solid rgb(121, 120, 120);
  //padding-bottom: 6px;

  .interaction-gauge {
    min-height: 10px;
    max-height: 10px;
    border-left: 1px solid rgb(121, 120, 120);
    border-right: 1px solid rgb(121, 120, 120);
    border-bottom: 1px solid rgb(121, 120, 120);
    //border-radius: 0 0 5px 5px;
    max-width: calc(100% - 20px);
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    background-color: rgba(255, 255, 255, 0.3);
    overflow: hidden;

    .interaction-total {
      min-height: 10px;
      max-height: 10px;
      background-color: #0f9d58;
    }

  }
}

.sprint-progress-line {
  max-height: 5px;
  min-height: 5px;
  display: flex;
  flex-direction: row;
  background-color: $backgroundLight;
  margin-top: -5px;

  .sprint-progress-fill {
    max-height: 5px;
    min-height: 5px;
    background-color: $predStatusBlue;
    width: 50%;
    box-shadow: -2px 5px 12px -1px #515151;
  }
}

.sprint-lockdown-container {
  background-color: #DB4437;
  text-align: center;
  width: 100%;
  padding: 10px;
  color: white;
  font-size: 16px;
}

.sprint-rocket-button {
  width: 25px;
  height: 25px;
  border-radius: 50% !important;
  background-color: $predStatusBlue !important;
  position: absolute;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: -10px;
  color: white;
  z-index: 500;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
    background-color: $predStatusBlue;
  }

  .sprint-button-icon {
    font-size: 15px;
    margin-top: 5px;
  }
}

.sprint-back {
  position: absolute;
  left: 5px;
  cursor: pointer;
  width: 20px;
  height: 20px;
  //border-style: solid;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 350;

  &:hover {
    font-weight: bold;
    font-size: 18px;
  }
}

.sprint-forward {
  position: absolute;
  right: 5px;
  cursor: pointer;
  width: 20px;
  height: 20px;
  //border-style: solid;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 350;

  &:hover {
    font-weight: bold;
    font-size: 18px;
  }
}

.sprint-timeline {
  display: flex;
  flex: 1;
  flex-direction: row;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  align-items: center;
  background-color: $colorLight;
  position: relative;


  .sprint-timeline-progress {
    position: absolute;
    left: 10px;
    background-image: linear-gradient(to bottom, rgb(243, 225, 173, 0.8), rgb(244, 180, 0, 0.8));
    min-height: 30px;
    max-height: 30px;
    border-right: 3px solid $predStatusRed;
    border-radius: 5px 0px 0px 0px;
  }

  .full {
    border-right: 0;
    border-radius: 5px;
  }

  .sprint-day {
    flex: 1;
    z-index: 300;
    min-height: 30px;
    max-height: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-left: 1px solid rgb(121, 120, 120);
    //margin-bottom: -5px;
    //border-top: 1px solid rgb(121, 120, 120);
    //border-bottom: 1px solid rgb(121, 120, 120);
    //background-color: rgba(255, 255, 255, 0.3);

    &:nth-of-type(1) {
      border-left: none
    }
    //&:nth-of-type(8) {
    //  border-right: 1px solid rgb(121, 120, 120);
    //  border-radius: 0px 5px 0px 0px;
    //}

    > p {
      font-size: 14px;
      font-weight: bold;
      margin: 0;
      padding: 0;
    }
  }
}

.incomplete-sprint {
  display: flex;
  flex-direction: row;
  flex: 1;
  //border-style: solid;
  align-items: center;
  justify-content: center;
  min-height: 25px;
  cursor: pointer;
  //border-left: 1px solid $colorSecondary;
}

.sprint-summary-container {
  min-height: 50px;
  display: flex;
  flex-direction: row;
  background-color: $colorLight;
  align-items: center;

  .sprint-titles {
    margin-left: 10px;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .sprint-dropdown-div {
    max-width: 100px;
    min-width: 100px;
    .sprint-date-dropdown {
      margin-left: 7px;
      margin-right: 7px;
      min-width: 150px;
      max-width: 150px;
      background-color: #f1f0f0;
    }
  }

  .sprint-summary-icon {
    margin-left: 10px;
  }

  .sprint-summary-figures {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    //gap: 20px;
    padding: 0;
    font-size: 14px;
    font-weight: bold;
    font-family: "Open Sans", sans-serif;
    //border-style: solid;

    .sprint-summary-total {
      flex: 1;
      //border-style: solid;
      padding-left: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;


      .sprint-total-div {
        display: flex;
        flex-direction: row;
        border: 1px solid $colorSecondary;
        background-color: #f1f0f0;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        flex: 1;
        margin-left: 8px;
        margin-right: 10px;
        font-size: 15px;
        align-items: center;
        min-height: 25px;
        overflow: hidden;

        .over-progressed {
          position: relative;
          min-height: 25px;
          max-height: 25px;
          border-right: 3px solid $predStatusGreen;
          display: flex;
          flex-direction: row;
          align-items: center;

          > p {
            color: $predStatusGreen;
            padding-right: 5px;
          }
        }

        .sprint-progress-bar-finish {
          min-height: 25px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          //background-color: $predStatusBlue;
          background-image: linear-gradient(to bottom, rgb(145, 182, 243), $predStatusBlue);
          //border-radius: 7px 0 0 7px;
          border-right: 1px solid $colorSecondary;
          cursor: pointer;
          > p {
            color: rgb(204, 217, 238);
          }
          &:hover {
            mask: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
          }
        }

        .sprint-progress-bar-finish-early {
          min-height: 25px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          //background-color: $predStatusBlue;
          background-image: linear-gradient(to bottom, $colorLight, $colorLight);
          //border-radius: 7px 0 0 7px;
          border-right: 1px solid $colorSecondary;
          //border-left: 1px solid $colorSecondary;
          cursor: pointer;
          > p {
            color: black;
          }
          &:hover {
            mask: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
          }
        }

        .non-sprint-progress-bar-finish-early {
          min-height: 25px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          //background-color: $predStatusBlue;
          background-image: linear-gradient(to bottom, $colorLight, $colorLight);
          //border-radius: 7px 0 0 7px;
          //border-right: 1px solid $colorSecondary;
          border-left: 1px solid $colorSecondary;
          cursor: pointer;
          > p {
            color: black;
          }
          &:hover {
            mask: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
          }
        }

        .sprint-progress-bar-start {
          min-height: 25px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          //background-color: $predStatusBlue;
          background-image: linear-gradient(to bottom, rgb(159, 206, 182), $predStatusGreen);
          //border-radius: 7px 0 0 7px;
          border-right: 1px solid $colorSecondary;
          //border-left: 1px solid $colorSecondary;
          cursor: pointer;
          > p {
              color: rgb(200, 241, 220);
          }
          &:hover {
            mask: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
          }
        }
        .sprint-progress-bar-start-unplanned {
          min-height: 25px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          //background-color: $predStatusBlue;
          background-image: linear-gradient(to bottom, rgb(191, 245, 218), rgb(159, 206, 182));
          //border-radius: 7px 0 0 7px;
          border-left: 1px solid $colorSecondary;
          cursor: pointer;
          > p {
            color: $predStatusGreen;
          }
          &:hover {
            mask: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
          }
        }


        .sprint-progress-bar-finish-unplanned {
          min-height: 25px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          //background-color: $predStatusBlue;
          background-image: linear-gradient(to bottom, rgb(193, 211, 239), rgb(145, 182, 243));
          //border-radius: 7px 0 0 7px;
          border-left: 1px solid $colorSecondary;
          cursor: pointer;
          > p {
            color: $predStatusBlue;
          }
          &:hover {
            mask: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
          }
        }

        .active-filter {
          // make this div appear bevelled
          background-image: linear-gradient(black, black) !important;
          box-shadow: -5px -5px -5px -5px rgba(0, 0, 0, 0.1);
          > p {
            color: white
          }
        }

        .finish-line {
          min-height: 25px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: right;
          //align-self: flex-end;
          //flex: 1;
          .finish-line-icon {
            margin-right: 5px;
          }

          > span {
            background-color: $backgroundLight;
            display: flex;
            flex-direction: row;
            align-items: center;
            min-width: 60px;
            max-width: 60px;
            min-height: 25px;
            border-left: 1px solid $colorSecondary;
            //border-style: solid;

            > p {
              margin: 0;
              min-width: 35px;
              max-width: 40px;
              text-align: right;
            }

            > div {
              min-height: 25px;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}

.tasks-sort-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $colorSecondary;
  padding: 10px 65px 10px 30px;
  font-size: 14px;
  font-weight: 500;
  font-family: "Open Sans", sans-serif;
  border-bottom: 1px solid $backgroundActive;

  .priority-task {
    display: flex;
  }

  .priority {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-right: 65px;
  }

  .critical {
    cursor: pointer;
    min-width: 140px;
  }

  .time {
    cursor: pointer;
    //display: flex;
    align-items: center;
    min-width: 120px;
  }

  .active {
    font-weight: 700;
  }

  .narrow {
    min-width: 100px;
  }

  .right-filters {
    display: flex;
    align-items: baseline;
    //gap: 80px;
    .task-header {
      text-align: right;
    }
  }
}

.blinking {
  animation: blinking-anim 1.2s infinite;
}

.deleting-label {
  color: $predStatusBlue;
  padding: 0 10px;
  position: absolute;
  background-color: $backgroundPrimary;
  border-radius: 50%;
  padding: 5px;
  top: -15px;
  left: -10px;
  font-size: 10px;
}

@keyframes blinking-anim {
  45% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.2;
  }
}

.flex-column-reverse {
  display: flex;
  flex-direction: column-reverse;
}

.relative {
  position: relative;
}

.opacity-7 {
  opacity: 0.7;
}

.drag-drop-wrap {
  width: 100%;
  position: relative;
  height: 100%;
  border: 3px dashed #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  transition: background-color 0.2s;
  z-index: 1000;

  &.loading {
    background-color: $backgroundActive;

    & > label {
      cursor: initial;
    }

    &:before {
      content: "Loading...";
    }
  }

  &.active {
    background-color: $backgroundMain;

    &:before {
      transform: scale(1.1);
    }
  }

  &.unsupport-file-extension {
    &:before {
      content: "Unsupport file extension";
      color: $backgroundError;
    }
  }

  &:before {
    content: "Drag & Drop Here";
    display: block;
    color: $colorPrimary;
    font-size: 22px;
    transition: 0.2s;
    transform: scale(1);
    font-weight: 400;
  }

  & > label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1000;
}

.input-reset {
  border: 1px solid $colorLight;

  &:focus,
  &:active {
    outline: none;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
}

.ui.progress {
  .bar {
    transition: 0.3s;
  }
}

.border-left-0 {
  border-left: 0;
}

.mr-10 {
  margin-right: 10px;
}

.d-flex {
  display: flex;
}

.d-inline-block {
  display: inline-block;
}

.word-break-all {
  word-break: break-all;
}

.ui.page.dimmer {
  z-index: 10000;
}

.ui.styled.accordion,
.ui.styled.accordion .accordion {
  border-radius: 0;

  i.icon {
    margin-right: 10px;
  }
}

.switch-wrap {
  display: flex;
  border: 1px solid;
  color: $colorPrimary;
  border-radius: 30px;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.switch-item {
  width: 70px;
  text-align: center;
  cursor: pointer;
  position: relative;
  padding: 10px 0;
  z-index: 10;
  &.active {
    color: #fff;
  }
}

.switch-highlighter {
  background: $predStatusBlue;
  width: 80px;
  border-radius: 30px;
  height: 100%;
  position: absolute;
  transition: 0.2s;
  &.right {
    left: 70px;
    width: 70px;
  }
  &.left {
    left: 0;
  }
}

.react-datepicker-time__input {
  margin-left: 5px !important;
}

.hiddenModal .modals {
  opacity: 0 !important;
}

