@import './varibals.scss';

.react-calendar__month-view__days__day--neighboringMonth {
  visibility: hidden;
}

.react-calendar__tile--now {
  font-weight: bold;
  background-color: inherit;
}


.react-calendar__tile {
  position: relative;
  outline: 1px solid #fff !important;
  color: #7f7f7f;

  &.react-calendar__month-view__days__day--weekend {
    color: #7f7f7f;
  }

  &.react-calendar__tile--active {
    color: #7f7f7f;

    &:hover abbr {
      color: #7f7f7f;
    }
  }

  &.react-calendar__tile--now {
    font-weight: 700;
  }

  &:disabled {
    color: #7f7f7f !important;
    opacity: 0.3;
  }

  &.react-calendar__tile--active {
    background-color: #fff;

    &:hover {
      color: initial;
      background-color: $colorFourth ;
    }
  }
}

.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
  background-color: $colorFourth;
}
